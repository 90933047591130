import React from 'react';
import Card from '../../PhotoModal/Card';
import './BoosterModal.css';
import ClearIcon from '@material-ui/icons/Clear';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector, useDispatch } from 'react-redux';
import { projectUpload } from 'src/redux/slices/user';
import { Container, Typography } from '@material-ui/core';
import ResumeRender from '../PdfViwer/resume-render';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
// import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#00308F',
    fontFamily: 'Segoe UI Symbol',
    marginTop: '3%',
    textDecoration: 'underline',
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: '2%'
  },
  desc: {
    fontStyle: 'italic',
    color: '#007FFF',
    marginTop: '2%'
  },
  link: {
    color: '#007FFF',
    fontFamily: 'Segoe UI Symbol',
    cursor: 'pointer'
  },
  close: {
    color: '#000000bf',
    marginLeft: '95%',
    marginTop: '1%',
    cursor: 'pointer'
  },
  modal: {
    height: '80%',
    width: '80%',
    position: 'fixed',
    overflow: 'hidden',
    zIndex: '100',
    top: '10vh',
    left: '12%',
    background: 'white',
    overflow: 'scroll'
  }
}));

const Backdrop = (props) => {
  const classes = useStyles();
  return (
    <div>
      <div className="backdrop" onClick={props.modalHandler}></div>
    </div>
  );
};

const ModalOverlay = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const talentId = useSelector((state) => state.authJwt.user._id);
  const talentCard = useSelector((state) => state.user.talentCard);
  const ct = props.counter;

  const title = talentCard.workSampleProject[ct].title;
  const file = talentCard.workSampleProject[ct].document;
  const desc = talentCard.workSampleProject[ct].description;
  const link = talentCard.workSampleProject[ct].link;

  return (
    <Modal open={true}>
      <Box className={classes.modal}>
        <ClearIcon className={classes.close} onClick={props.modalHandler}>
          Close
        </ClearIcon>
        <Container>
          <Typography className={classes.title}>{title}</Typography>
          <a href={link}>
            <Typography variant="body1" className={classes.link}>
              {link}
            </Typography>
          </a>
          <Typography variant="body1" className={classes.desc}>
            {desc}
          </Typography>
          <ResumeRender resume={file} />
        </Container>
        {/* <div className={classes.content}>
        <input
          accept="image/*"
          id="icon-button-file"
          type="file"
          style={{ display: 'none' }}
          onChange={imgHandler}
        />
        <label htmlFor="icon-button-file">
          <IconButton
            color="secondary"
            aria-label="upload picture"
            component="span"
          >
            <PhotoCamera />
          </IconButton>
        </label>
        {selectedImage != null && (
          // <img src={imgData} height="78%" />
          <PhotoCard>
            <Cropper
              image={imgData}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </PhotoCard>
        )}
        <div className="viewer">
          <div>{croppedArea && <Output croppedArea={croppedArea} />}</div>
        </div>
      </div> */}
        {/* <div>
        <Paper className={classes.plus}>
          <input
            name="file"
            accept="application/pdf"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={(event) => {
              let updatedValue = {};
              updatedValue = { file: event.target.files[0] };
              setProjectData((prevData) => ({
                ...prevData,
                ...updatedValue
              }));
            }}
          />
          {projectData.file != null ? (
            <div className={classes.afterupload}>
              <CheckIcon className={classes.span1} />
              <span className={classes.span1}>{projectData.file.name}</span>
            </div>
          ) : (
            <label htmlFor="contained-button-file">
              + <span className={classes.span}>Upload A File</span>
            </label>
          )}
        </Paper>
        <input
          name="link"
          id="description"
          type="text"
          className={classes.ip}
          onChange={(event) => {
            let updatedValue = {};
            updatedValue = { description: event.target.value };
            setProjectData((prevData) => ({
              ...prevData,
              ...updatedValue
            }));
          }}
        />
        <label htmlFor="description">
          + <span className={classes.span}>Enter Description</span>
        </label>
        <input
          name="link"
          id="link"
          type="text"
          className={classes.ip}
          onChange={(event) => {
            let updatedValue = {};
            updatedValue = { link: event.target.value };
            setProjectData((prevData) => ({
              ...prevData,
              ...updatedValue
            }));
          }}
        />
        <label htmlFor="link">
          + <span className={classes.span}>Enter Link</span>
        </label>
        <input
          name="link"
          id="description"
          type="text"
          className={classes.ip}
          onChange={(event) => {
            let updatedValue = {};
            updatedValue = { title: event.target.value };
            setProjectData((prevData) => ({
              ...prevData,
              ...updatedValue
            }));
          }}
        />
        <label htmlFor="description">
          + <span className={classes.span}>Enter Title</span>
        </label>
      </div>
      <footer className="actions">
        <button id="save" onClick={saveHandler}>
          Save
        </button>
        {/* <button onClick={props.modalHandler}>Close</button> */}
        {/* </footer> */}
      </Box>
    </Modal>
  );
};

const NewModal = (props) => {
  return (
    <React.Fragment>
      {/* <Backdrop modalHandler={props.modalChange} /> */}
      <ModalOverlay modalHandler={props.modalChange} counter={props.counter} />
    </React.Fragment>
  );
};

export default NewModal;
