import React from 'react';
import Page from 'src/components/Page';
import MultiChipSelect from 'src/views/TalentCardView/HardSkillPanel/Skill';
import Software from 'src/views/TalentCardView/HardSkillPanel/Software';
import Grit from 'src/views/TalentCardView/SoftSkill/Grit';
import starWarsNames from 'starwars-names';
import CoreValue from './CoreValue';
import OrganisationValue from './OrganisationValue';
import { withStyles } from '@material-ui/styles';
import ReplyIcon from '@material-ui/icons/Reply';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { SafeAnchor } from 'react-bootstrap';

// const useStyles = (theme) => ({
//   paper: {
//     display: 'flex'
//   }
// });

class Login extends React.Component {
  allItems = starWarsNames
    .random(7)
    .map((s) => ({ name: s, id: s.toLowerCase() }));
  state = {
    items: this.allItems,
    selectedItem: []
  };

  handleChange = (selectedItem) => {
    if (this.state.selectedItem.includes(selectedItem)) {
      this.removeSelectedItem(selectedItem);
    } else {
      this.addSelectedItem(selectedItem);
    }
  };

  addSelectedItem(item) {
    this.setState(({ selectedItem, items }) => ({
      inputValue: '',
      selectedItem: [...selectedItem, item],
      items: items.filter((i) => i.name !== item)
    }));
  }

  removeSelectedItem = (item) => {
    this.setState(({ selectedItem, items }) => ({
      inputValue: '',
      selectedItem: selectedItem.filter((i) => i !== item),
      items: [...items, { name: item, id: item.toLowerCase() }]
    }));
  };

  handleChangeInput = (inputVal) => {
    const t = inputVal.split(',');
    if (JSON.stringify(t) !== JSON.stringify(this.state.selectedItem)) {
      this.setState({ inputValue: inputVal });
    }
  };

  render() {
    const { selectedItem, items } = this.state;
    // const classes = useStyles();
    return (
      <div>
        <Link
          to="/app/talent-card"
          // className={classes.icontext}
          color="primary"
          // style={{ display: 'inline-flex' }}
        >
          <ArrowBackIcon
            style={{ color: '#003458', textDecoration: 'underline' }}
          />
        </Link>
        <img src="/static/images/png/comingsoon.PNG" width="100%" />
        {/* <FormGroup>
          <div>
            <Grid container>
              <Grid item xs={6}>
                <Typography color="primary" variant="h6">
                  Soft Skills & Assessments
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                spacing={3}
              >
                <Button style={{ background: '#36B09A', width: '130px' }}>
                  <SaveAltIcon />
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>
          <Divider />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel>Core Values</FormLabel>
                <CoreValue
                  onInputValueChange={this.handleChangeInput}
                  inputValue={this.state.inputValue}
                  availableItems={items}
                  selectedItem={selectedItem}
                  onChange={this.handleChange}
                  onRemoveItem={this.removeSelectedItem}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel>Organisation Value</FormLabel>
                <OrganisationValue
                  onInputValueChange={this.handleChangeInput}
                  inputValue={this.state.inputValue}
                  availableItems={items}
                  selectedItem={selectedItem}
                  onChange={this.handleChange}
                  onRemoveItem={this.removeSelectedItem}
                />
              </FormControl>
            </Grid>
          </Grid>
        </FormGroup>
        <Divider />
        <br />
        <Grit /> */}
      </div>
    );
  }
}
export default Login;
