import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import { updateTalent } from 'src/redux/slices/user';

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Select,
  FormLabel,
  TextField,
  CircularProgress,
  Fade,
  Typography,
  MenuItem,
  Dialog,
  DialogContent,
  InputAdornment,
  IconButton,
  Input
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20'
  },
  paper: {
    // backgroundColor: theme.palette.background.paper,
    // border: '0px solid #000',
    // padding: theme.spacing(2, 4, 3),
    // width: '50%',
    borderRadius: '20px !important'
  },
  cardHeader: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: 'capitalize'
    // justifyContent: 'space-between',
    // display: 'flex'
  },
  input: {
    width: '-webkit-fill-available'
  },
  formControl: {
    width: '100%'
  },
  button: {
    background: '#36B09A',
    width: '140px'
  },
  u: {
    color: '#1786D0',
    fontSize: '14px',
    cursor: 'pointer'
  },
  clos: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  AddEdu: {
    border: '2px dashed #1786D0',
    color: '#1786D0',
    textAlign: 'center',
    padding: '25px',
    borderRadius: '3px',
    cursor: 'pointer'
  }
}));
export default function ResumePopup({ open, handleClose, handleOpen }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const talentId = useSelector((state) => state.authJwt.user._id);
  let history = useHistory();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    // setIsSelected(true);
  };

  // const handleSubmission = () => {
  //   setLoading(true);
  //   const formData = new FormData();

  //   formData.append('resume', selectedFile);

  //   var reader = new FileReader();

  //   reader.readAsDataURL(selectedFile);
  //   let data = {
  //     binaryData: '',
  //     filename: selectedFile.name
  //   };
  //   reader.onload = async function (e) {
  //     // O arquivo de texto será impresso aqui
  //     let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
  //     if (encoded.length % 4 > 0) {
  //       encoded += '='.repeat(4 - (encoded.length % 4));
  //     }
  //     console.log(encoded);
  //     data.binaryData = encoded;
  //   };
  //   // showLoader();
  //   console.log('heloooo');

  //   reader.onloadend = function (e) {
  //     axios
  //       .post(`/api/talent/${talentId}/parser`, data, {
  //         headers: {
  //           'Content-Type': 'application/json'
  //         }
  //       })
  //       .then((response) => {
  //         let data = response.data;
  //         delete data['TemplateOutput'];
  //         delete data['HtmlResume'];
  //         delete data['Qualification'];
  //         delete data['DetailResume'];

  //         let experienceFromParsedData = [];
  //         data.SegregatedExperience.map((experience) => {
  //           experienceFromParsedData.push({
  //             location:
  //               experience.Location.City + ', ' + experience.Location.Country,
  //             ctc: '',
  //             currencyType: '',
  //             company: experience.Employer.EmployerName,
  //             designation: experience.JobProfile.Title,
  //             description: experience.JobDescription,
  //             startDate: moment(experience.StartDate).format('MMMM YYYY'),
  //             endDate: moment(experience.EndDate).format('MMMM YYYY')
  //           });
  //         });
  //         let qualificationFromParsedData = [];
  //         data.SegregatedQualification.map((qualification) => {
  //           qualificationFromParsedData.push({
  //             universityName: qualification.Institution.Name,
  //             degree: qualification.Degree.DegreeName,
  //             startDate: moment(qualification.StartDate).format('MMMM YYYY'),
  //             endDate: moment(qualification.EndDate).format('MMMM YYYY'),
  //             major: '',
  //             description: '',
  //             gpa: null
  //           });
  //         });
  //         let skills = [];
  //         if (data.SegregatedSkill.length > 0) {
  //           data.SegregatedSkill.map((skill, key) =>
  //             skills.push({ value: skill.Skill, label: skill.Skill, key })
  //           );
  //         }
  //         let updatedData = {
  //           workExperience: experienceFromParsedData,
  //           education: qualificationFromParsedData,
  //           skills: skills,
  //           currentDesignation: data.JobProfile,
  //           currentLocation: data.currentLocation,
  //           preferredLocation: data.expectedLocation,
  //           currentCurrencyType: data.currentCurrencyType,
  //           currentSalary: data.currentSalary,
  //           preferredSalary: data.expectedSalary,
  //           experience: data.WorkedPeriod.TotalExperienceInYear,
  //           currentCompany: data.CurrentEmployer,
  //           summary: data.Summary,
  //           parsedResumeData: {
  //             educationResume: [],
  //             workExperience: [],
  //             resumeAnalytics: {},
  //             AllParsedData: data
  //           }
  //         };
  //         dispatch(updateTalent(talentId, updatedData));
  //         return axios
  //           .post(`/api/talent/${talentId}/resume/upload`, formData)
  //           .then((result) => {
  //             console.log('Success:', result);
  //             setLoading(false);
  //             // history.push('/loadingscreen');
  //             // history.push('/app/talent-card/background')
  //             setTimeout(() => {
  //               history.push('/loadingscreen');
  //               setTimeout(() => {
  //                 history.push('/app/talent-card/background');
  //               }, 1000); 
  //             }, 1000);
  //           })
  //           .catch((error) => {
  //             setLoading(false);
  //             console.error('Error:', error);
  //           });
  //       });
  //   };
  // };

  const handleSubmission = async (e) => {
    e.preventDefault()
    setLoading(true)


      try{
        
        
        const fd = new FormData();

        fd.append('resume', selectedFile);
    
        var reader = new FileReader();
        let fileData = {
          binaryData: '',
          filename: "hardik.pdf"
        };
        reader.readAsDataURL(selectedFile);
        reader.onload = async function (e) {
          // O arquivo de texto será impresso aqui
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
          if (encoded.length % 4 > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
          }
          console.log(encoded);
          fileData.binaryData = encoded;
        };
        // showLoader();
        console.log('heloooo');
    
        reader.onloadend = function (e) {
          var data = JSON.stringify({
            filedata: `${fileData.binaryData}`,
            filename: `${fileData.filename}`,
            userkey: "LJUTM85ZJNO",
            version: "8.0.0",
            subuserid: "Keshav Kumar",
          });
          var config = {
            method: "post",
            url: "https://rest.rchilli.com/RChilliParser/Rchilli/parseResumeBinary",
            transformRequest: [(data, headers) => {     delete headers.common.Authorization;    return data }],
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };
          console.log("parser", data)
          axios(config)
            .then((response) => {
              let data = response.data.ResumeParserData;
              delete data['TemplateOutput'];
              delete data['HtmlResume'];
              delete data['Qualification'];
              delete data['DetailResume'];
    
              let experienceFromParsedData = [];
              data.SegregatedExperience.map((experience) => {
                experienceFromParsedData.push({
                  location:
                    experience.Location.City + ', ' + experience.Location.Country,
                  ctc: '',
                  currencyType: '',
                  company: experience.Employer.EmployerName,
                  designation: experience.JobProfile.Title,
                  description: experience.JobDescription,
                  startDate: moment(experience.StartDate).format('MMMM YYYY'),
                  endDate: moment(experience.EndDate).format('MMMM YYYY')
                });
              });
              let qualificationFromParsedData = [];
              data.SegregatedQualification.map((qualification) => {
                qualificationFromParsedData.push({
                  universityName: qualification.Institution.Name,
                  degree: qualification.Degree.DegreeName,
                  startDate: moment(qualification.StartDate).format('MMMM YYYY'),
                  endDate: moment(qualification.EndDate).format('MMMM YYYY'),
                  major: '',
                  description: '',
                  gpa: null
                });
              });
              let skills = [];
              if (data.SegregatedSkill.length > 0) {
                data.SegregatedSkill.map((skill, key) =>
                  skills.push({ value: skill.Skill, label: skill.Skill, key })
                );
              }
              let updatedData = {
                workExperience: experienceFromParsedData,
                education: qualificationFromParsedData,
                skills: skills,
                experience: data.WorkedPeriod.TotalExperienceInYear,
                currentCompany: data.CurrentEmployer,
                summary: data.Summary,
                parsedResumeData: {
                  educationResume: [],
                  workExperience: [],
                  resumeAnalytics: {},
                  AllParsedData: data
                }
              };
              console.log(data, '----', updatedData, '---')
              dispatch(updateTalent(talentId, updatedData));
              return axios
                .post(`/api/talent/resume`, fd)
                .then((result) => {
                  console.log('Success:', result);
                  setLoading(false);

                  setTimeout(() => {
                    history.push('/loadingscreen')
                  setTimeout(() => {
                    history.push('/app/talent-card/background')
                  }, 3000)
                  }, 100)

                })
                .catch((error) => {
                //   setLoading(false);
                  console.error('Error:', error);
                });
            });
        };
        console.log(res)
      }catch(err){
        console.log(err)
      }
    console.log('form Submitted')
}


  console.log('resume files', selectedFile);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.paper}
        fullWidth
        maxWidth="md"
      >
        <MuiDialogTitle className={classes.cardHeader}>
          <div className={classes.clos}>
            <Typography variant="h6">Change Resume</Typography>
            <IconButton onClick={handleClose} style={{ padding: '0px' }}>
              <HighlightOffIcon style={{ color: 'white', fontSize: '30px' }} />
            </IconButton>
          </div>
        </MuiDialogTitle>
        <DialogContent>
          <Typography color="primary"> Please upload your resume</Typography>
          <br />

          <Box className={classes.AddEdu}>
            <FormLabel for="resume">
              <storng>Choose File</storng>
            </FormLabel>
            <Input
              id="resume"
              style={{ display: 'none' }}
              type="file"
              onChange={changeHandler}
            />
          </Box>
          <FormHelperText>
            {selectedFile ? selectedFile.name : ''}
          </FormHelperText>
          <br />
          <br />
          <div style={{ textAlign: 'end' }}>
            <Button
              variant="contained"
              color="secondary"
              disabled={selectedFile ? 0 : 1}
              // component="span"
              // style={{ width: '100%' }}
              onClick={handleSubmission}
            >
              {loading ? <CircularProgress /> : 'Confirm'}
            </Button>
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
