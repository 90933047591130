import { alpha } from '@material-ui/core/styles';

const PRIMARY = {
  light: '#61BDFB',
  main: '#003458',
  dark: '#1786D0'
};

const SECONDARY = {
  light: 'rgba(54, 176, 154, 0.6)',
  main: '#21C8AA',
  dark: 'rgba(54, 176, 154, 0.8)'
};
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F1F4F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};
const palette = {
  primary: {
    ...PRIMARY,
    contrastText: '#fff'
  },
  secondary: {
    ...SECONDARY,
    contrastText: '#fff'
  },
  background: {
    paper: '#fff',
    default: '#fff',
    background: 'rgba(152, 179, 188,0.5)'
  },
  text: {
    primary: '#fff',
    secondary: '#000',
    default: '#F1F4F8'
  },
  grey: GREY,
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default palette;
