import palette from './palette';
import breakpoints from './breakpoints';
import typography from './typography';
import { CssBaseline } from '@material-ui/core';
import componentsOverride from './overrides';

import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import React, { useMemo } from 'react';

ThemeConfig.propTypes = {
  children: PropTypes.node
};
function ThemeConfig({ children }) {
  // const { themeMode, themeDirection } = useSettings();
  // const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      palette: palette,
      // shadows: shadows[isLight ? 'light' : 'dark'],
      typography: {
        fontFamily: 'Matter'
      },
      // shape: borderRadius,
      breakpoints: breakpoints,
      // direction: themeDirection,
      overrides: componentsOverride({
        theme: {
          palette: palette
          // shadows: shadows[isLight ? 'light' : 'dark'],
          // typography: typography,
          // shape: borderRadius,
          // direction: themeDirection
        }
      })
    }),
    []
  );

  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default ThemeConfig;
