import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import AppLayout from 'src/layout';
import TalentCardView from 'src/views/TalentCardView';
// import DashboardLayout from 'src/layouts/DashboardLayout';


// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  layout: AppLayout,
  guard: AuthProtect,
  // excludePaths: [PATH_APP.general.talentCardById],
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.general.resume,
    //   component: lazy(() => import('src/views/TalentCardView/Resume'))
    // },
    {
      exact: true,
      path: PATH_APP.general.talentCard,
      component: lazy(() => import('src/views/TalentCardView'))
    },
    {
      exact: true,
      path: PATH_APP.general.talentCardById,
      component: lazy(() => import('src/views/TalentCard/TalentCard')),
      guard:null
       },
       {
        exact: true,
        path: PATH_APP.general.talentCardNew,
        component: lazy(() => import('src/views/TalentCard/TalentCard'))
      },
    {
      exact: true,
      path: PATH_APP.general.talentCardBackground,
      layout: TalentCardView,
      component: lazy(() =>
        import('src/views/TalentCardView/BackgroundPanel/Index.js')
      )
    },
    {
      exact: true,
      path: PATH_APP.general.talentCardHardSkills,
      layout: TalentCardView,
      component: lazy(() => import('src/views/TalentCardView/HardSkillPanel'))
    },
    {
      exact: true,
      path: PATH_APP.general.talentCardSoftSkills,
      layout: TalentCardView,
      component: lazy(() => import('src/views/TalentCardView/SoftSkill'))
    },
    // {
    //   exact: true,
    //   path: PATH_APP.general.talentCardBooster,
    //   layout: TalentCardView,
    //   component: lazy(() => import('src/views/TalentCardView/Booster/Booster'))
    // },
    {
      exact: true,
      path: PATH_APP.general.talentCardBoosterNew,
      layout: TalentCardView,
      component: lazy(() => import('src/views/TalentCardView/BoosterNew/Booster'))
    },
    
    {
      exact: true,
      path: PATH_APP.general.opportunities,
      component: lazy(() => import('src/views/OpportunitiesTabs/JobTabs'))
    },
   
    {
      exact: true,
      path: PATH_APP.general.opportunities2,
      component: lazy(() => import('src/views/OpportunitiesView/ViewApplication/OpportunityListNew'))
    },
    {
      exact: true,
      path: PATH_APP.general.opportunitiesDetail,
      guard: null,
      component: lazy(() =>
        import('src/views/OpportunitiesView/OpportunityCard')
      )
    },
    {
      exact: true,
      path: PATH_APP.general.opportunitiesDetail,
      guard: null,
      component: lazy(() =>
        import('src/views/OpportunitiesView/OpportunityList')
      )
    },
    {
      exact: true,
      path: PATH_APP.general.opportunitiesTabs,
      guard: null,
      component: lazy(() =>
        import('src/views/OpportunitiesTabs/JobTabs')
      )
    },
    {
      exact: true,
      path: PATH_APP.general.inbox,
      component: lazy(() => import('src/views/InboxView'))
    },
    {
      exact: true,
      path: PATH_APP.general.standout,
      component: lazy(() => import('src/views/StandoutView'))
    },
    {
      exact: true,
      path: PATH_APP.general.personalityAssessmentDetails,
      component: lazy(() => import('src/views/StandoutView/PersonalityAssessment/PersonalityAssessmentDetails'))
    },
    {
      exact: true,
      path: PATH_APP.general.personalityAssessmentShortAssessment,
      component: lazy(() => import('src/views/StandoutView/PersonalityAssessment/ShortAssessment'))
    },
    {
      exact: true,
      path: PATH_APP.general.personalityAssessmentShortResults,
      component: lazy(() => import('src/views/StandoutView/PersonalityAssessment/ShortResults'))
    },
    {
      exact: true,
      path: PATH_APP.general.personalityAssessmentLongAssessment,
      component: lazy(() => import('src/views/StandoutView/PersonalityAssessment/LongAssessment'))
    },
    {
      exact: true,
      path: PATH_APP.general.personalityAssessmentLongResults,
      component: lazy(() => import('src/views/StandoutView/PersonalityAssessment/LongResults'))
    },
    // {
    //   exact: true,
    //   path: PATH_APP.general.talentDetails,
    //   component: lazy(() => import('src/views/auth/MultistepForm/MultiStep'))
    // },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
