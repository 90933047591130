import React from 'react';
// import Logo from 'src/components/Logo';
import Page from '../../components/Page';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import {
  MotionContainer,
  varBounce,
  varBounceIn
} from 'src/components/Animate';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, Container } from '@material-ui/core';
import './page.css';
import { PATH_PAGE } from 'src/routes/paths';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: 'flex',
  //   minHeight: '100%',
  //   alignItems: 'center',
  //   paddingTop: theme.spacing(15),
  //   paddingBottom: theme.spacing(10)
  // },
  header: {
    top: 0,
    left: 0,
    lineHeight: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 5, 0)
    }
  }
}));

// ----------------------------------------------------------------------

function Page404View() {
  const classes = useStyles();

  return (
    <Page title="Qrata Talent" className={classes.root}>
      <header className={classes.header}>
        <RouterLink to="/">{/* <Logo /> */}</RouterLink>
      </header>

      {/* <div class="container">
          <h1>Oops! Page Not Found</h1>
          <p>We're sorry, but the page you are looking for could not be found.</p>
          <p>Please check the URL or go back to the <Button to="/" size="large" variant="contained" component={RouterLink}>Home</Button></p>
          <p class="emoji">¯\_(ツ)_/¯</p>
      </div> */}
      {/* <div className='container'>
      <div className="not-found-container">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-emoji">¯\_(ツ)_/¯</p>
        <p className="not-found-text">Oops! Page not found</p>
        <p className="not-found-text">Looks like you've stumbled upon an uncharted territory.</p>
        <p className="not-found-text">But don't worry, we've got a map:</p>
        <Button to="/" size="large" variant="contained" component={RouterLink}>Home</Button>
      </div>
    </div> */}
      <div className="container">
        <div className="not-found-container">
          <h1 className="not-found-title">404</h1>
          <p className="not-found-text">Oops! Page not found</p>
          <div className="animated-background">
            <div className="particles">
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
            </div>
          </div>
          <p className="not-found-text">
            Looks like you've wandered off the path.
          </p>
          <p className="not-found-text">Let's find our way back:</p>
          <Button
            to={PATH_PAGE.auth.login}
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Home
          </Button>
        </div>
      </div>
    </Page>
  );
}

export default Page404View;
