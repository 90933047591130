import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box } from '@material-ui/core';

import ExperienceModel from './ExperienceModel';
import ExperienceCard from './ExperienceCard';
// import profile from '/static/images/png/profile.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      //   margin: theme.spacing(1),
      width: '120px',
      height: '120px'
    }
  },
  paper: {
    width: 'auto',
    height: 'auto',
    background: '#F1F4F8',
    padding: '10px'
  },
  align: {
    textAlign: 'end'
  },
  font: {
    fontSize: '18px',
    lineHeight: '21px'
  },
  font2: {
    fontSize: '14px'
  },
  AddEdu: {
    border: '2px dashed #1786D0',
    color: '#1786D0',
    textAlign: 'center',
    padding: '25px',
    borderRadius: '3px'
  },
  txtcolor: {
    opacity: '0.4',
    fontStyle: 'italic'
  }
}));

const DATA = {
  company: '',
  location: '',
  ctc: '',
  currencyType: '',
  designation: '',
  description: '',
  startDate: '',
  endDate: ''
};

export default function Experience() {
  const classes = useStyles();
  const workExperience = useSelector(
    (state) => state.user.talentCard.workExperience
  );

  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);

  const [data, setData] = React.useState({
    company: '',
    location: '',
    salary: '',
    currencyType: '',
    designation: '',
    description: '',
    startDate: '',
    endDate: ''
  });
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setData(DATA);
    setEdit(false);
  };

  const onEdit = (data) => {
    setData(data);
    setEdit(true);
    handleOpen();
  };

  console.log('experience', workExperience, data);

  return (
    <div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography color="primary" className={classes.txtcolor}>
              Experience
            </Typography>
          </Grid>
          {/* <Grid item xs={6} className={classes.align}></Grid> */}
          <Box
            sx={{
              maxHeight: '500px',
              width: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
              padding: '0px 8px'
            }}
          >
            {/* <Grid> */}
            {!workExperience.length ? (
              <Typography variant="subtitle1" color="primary">
                No data found
              </Typography>
            ) : (
              workExperience.map((workExp) => (
                <Grid item xs={12} key={workExp._id}>
                  <ExperienceCard workExp={workExp} onEdit={onEdit} />
                </Grid>
              ))
            )}
            {/* </Grid> */}
            <br />
          </Box>
          <br />
          <Box sx={{ width: '100%', padding: '8px' }}>
            <ExperienceModel
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              data={data}
              isEdit={edit}
            />
          </Box>
        </Grid>
      </div>
    </div>
  );
}
