// import NProgress from 'nprogress';
import AppRoutes from './AppRoutes';
// import HomeRoutes from "./HomeRoutes";
import { Switch, Route } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import React, { Suspense, Fragment, lazy } from 'react';
import { PATH_PAGE, PATH_APP } from './paths';
import GuestProtect from 'src/components/Auth/GuestProtect';
import TopBarWithSignUp from 'src/layout/TopBarWithSignUp';
import Page404View from 'src/views/Page404View/index';

// ----------------------------------------------------------------------

// const nprogressStyle = makeStyles((theme) => ({
//   '@global': {
//     '#nprogress': {
//       pointerEvents: 'none',
//       '& .bar': {
//         top: 0,
//         left: 0,
//         height: 2,
//         width: '100%',
//         position: 'fixed',
//         zIndex: theme.zIndex.snackbar,
//         backgroundColor: theme.palette.primary.main,
//         boxShadow: `0 0 2px ${theme.palette.primary.main}`
//       },
//       '& .peg': {
//         right: 0,
//         opacity: 1,
//         width: 100,
//         height: '100%',
//         display: 'block',
//         position: 'absolute',
//         transform: 'rotate(3deg) translate(0px, -4px)',
//         boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
//       }
//     }
//   }
// }));

// function RouteProgress(props) {
//   nprogressStyle();

//   NProgress.configure({
//     speed: 500,
//     showSpinner: false
//   });

//   useEffect(() => {
//     NProgress.done( );
//     return () => {
//       NProgress.start();
//     };
//   }, []);

//   return <Route {...props} />;
// }

export function renderRoutes(routes = []) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      <Route component={Page404View} />
      </Switch>
    </Suspense>
  );
}

const routes = [
  {
    exact: true,
    path: PATH_APP.general.talentCardById,
    component: lazy(() => import('src/views/TalentCard/TalentCard')),
    guard:null
     },
  {
    exact: true,
    // guard: GuestProtect,
    path: '/',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/companyInfo/:jobId',
    component: lazy(() => import('src/views/OpportunitiesView/ViewApplication/PreviewCompany.jsx'))
  },
  {
    exact: true,
    // guard: GuestProtect,
    path: PATH_PAGE.auth.login,
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    // guard: GuestProtect,
    path: PATH_PAGE.auth.register,
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/verification',
    component: lazy(() =>
      import('src/views/auth/VerificationView/VerificationForm')
    )
  },
  {
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.auth.forgetPassword,
    component: lazy(() => import('src/views/auth/ForgetPasswordView'))
  },
  {
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.auth.resetPassword,
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.talentDetails,
    component: lazy(() => import('src/views/auth/MultistepForm/MultiStep'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.googleLoginAndRegister,
    component: lazy(() => import('src/views/auth/SocialLoginAndRegisterr'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.emailValidate,
    component: lazy(() => import('src/views/auth/EmailVerification/EmailVerification'))
  },
  {
    exact: true,
    path: '/app/welcome',
    component: lazy(() => import('src/views/WelcomeView/Welcomepage'))
  },
  {
    exact: true,
    path: '/carousel',
    component: lazy(() => import('src/views/CarouselView'))
  },
  {
    exact: true,
    path: '/loadingscreen',
    component: lazy(() => import('src/views/LoadingScreen/LoadingScreen'))
  },
  {
    exact: true,
    path: '/claim-profile/:token',
    component: lazy(() => import('src/views/auth/ClaimProfile'))
  },
  {
    exact: true,
    path: '/jobTabs',
    component: lazy(() => import('src/views/OpportunitiesTabs/JobTabs'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/Page404View/index'))
  },
  // {
  //   exact: true,
  //   path: '/resume',
  //   component: lazy(() => import('src/views/TalentCardView/Resume'))
  // },
  {
    exact: true,
    path: '/opportunities/:id',
    layout: TopBarWithSignUp,
    component: lazy(() => import('src/views/OpportunitiesView/OpportunityCard'))
  },
  // App Routes
  AppRoutes

  // Home Routes
  // HomeRoutes
];

export default routes;
