import React from 'react';
import ReactDOM from 'react-dom';
import Card from './Card';
import { useState } from 'react';
import classes from './Modal.module.css';
import './Button.module.css';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import Cropper from 'react-easy-crop';
import { useCallback } from 'react';
import PhotoCard from './PhotoCard';
import axios from 'axios';
import { imageUpload } from '../../../redux/slices/user.js';
import { useSelector, useDispatch } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';

const CROP_AREA_ASPECT = 3 / 2;

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.modalHandler}></div>;
};

const ModalOverlay = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const dispatch = useDispatch();
  const talentId = useSelector((state) => state.authJwt.user._id);
  // const [croppedArea, setCroppedArea] = useState(null);

  const imgHandler = (event) => {
    console.log(event.target.files[0]);
    setSelectedImage(event.target.files[0]);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels);
    window.localStorage.setItem('croppedArea', JSON.stringify(croppedArea));
  }, []);

  const saveImage = () => {
    setTimeout(() => {
      dispatch(imageUpload(selectedImage));
      props.modalHandler();
    }, 2000);
  };

  // const Output = ({ croppedArea }) => {
  //   const scale = 100 / croppedArea.width;
  //   const transform = {
  //     x: `${-croppedArea.x * scale}%`,
  //     y: `${-croppedArea.y * scale}%`,
  //     scale,
  //     width: 'calc(100% + 0.5px)',
  //     height: 'auto'
  //   };

  //   const imageStyle = {
  //     transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
  //     width: transform.width,
  //     height: transform.height
  //   };

  //   return (
  //     <div
  //       className="output"
  //       style={{ paddingBottom: `${100 / CROP_AREA_ASPECT}%` }}
  //     >
  //       <img src={imgData} alt="" style={imageStyle} />
  //     </div>
  //   );
  // };

  return (
    <Card className={classes.modal}>
      <header className={classes.header}>
        <h2>Edit Profile Photo</h2>
        <button>
          <ClearIcon onClick={props.modalHandler}>Close</ClearIcon>
        </button>
      </header>
      <div className={classes.content}>
        <input
          accept="image/*"
          id="icon-button-file"
          type="file"
          style={{ display: 'none' }}
          onChange={imgHandler}
        />
        <label htmlFor="icon-button-file">
          <IconButton
            color="secondary"
            aria-label="upload picture"
            component="span"
          >
            <PhotoCamera />
          </IconButton>
        </label>
        {selectedImage != null && (
          // <img src={imgData} height="78%" />
          <PhotoCard>
            <Cropper
              image={imgData}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </PhotoCard>
        )}
        {/* <div className="viewer">
          <div>{croppedArea && <Output croppedArea={croppedArea} />}</div>
        </div> */}
      </div>
      <footer className={classes.actions}>
        <button id="save" onClick={saveImage}>
          Save
        </button>
        {/* <button onClick={props.modalHandler}>Close</button> */}
      </footer>
    </Card>
  );
};

const Modal = (props) => {
  return (
    <React.Fragment>
      <Backdrop modalHandler={props.modalHandler} />
      <ModalOverlay modalHandler={props.modalHandler} />
    </React.Fragment>
  );
};

export default Modal;
