import {
  Button,
  Grid,
  Card,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  Input,
  FormHelperText,
  Chip,
  Paper,
  Typography
} from '@material-ui/core';
import * as Yup from 'yup';
import React, { useState } from 'react';
import Select from 'react-select/creatable';
import { FormikProvider, Form, useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.error.main
  },
  bgRed: {
    backgroundColor: theme.palette.background.paper
  },
  radiocolor: {
    color: '#003458'
  },
  paper: {
    padding: '40px'
  },
  gridchip: {
    textAlign: 'left',
    display: 'flex'
  },
  chip: {
    background: '#1786D0',
    color: 'white'
  },
  rating: {
    textAlign: 'right'
  }
}));

const skills = [
  { key: 1, value: 'Figma', label: 'Figma' },
  { key: 2, value: 'Adobe Creative Suite', label: 'Adobe Creative Suite' },
  { key: 3, value: 'Webflow', label: 'Webflow' },
  { key: 4, value: 'Invision', label: 'Invision' },
  { key: 5, value: 'Prototype.io', label: 'Prototype.io' },
  { key: 6, value: 'Miro', label: 'Miro' },
  { key: 7, value: 'Mural', label: 'Mural' }
];

const StylesSelect = {
  option: (provided) => ({
    ...provided,
    color: '#000',
    ':nth-child(odd)': {
      backgroundColor: '#F1F4F8'
    }
  })
  // multiValue: (styles, { data }) => {
  //   return {
  //     ...styles,
  //     backgroundColor: 'red'
  //   };
  // }
};
export default function Software2(_props) {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      gender: '',
      Skills: []
    }
  });

  const { values, handleSubmit, setFieldValue } = formik;

  // console.log('formik data', formik.values);
  return (
    // <Paper className={classes.paper}>
    <FormikProvider value={formik}>
      <Form noValidate>
        <Grid container spacing={3}>
          {/* <Grid> */}
          {/* <FormLabel id="demo-mutiple-chip-label">Software</FormLabel> */}
          <Grid item xs={12} style={{ lineHeight: 1 }}>
            <Select
              isMulti
              styles={StylesSelect}
              options={skills}
              value={values.Skills}
              isClearable
              onChange={(selectedOptions) =>
                setFieldValue('Skills', selectedOptions)
              }
            />
            <br />
            <Typography color="primary" variant="body2">
              Note : If you are unable to find a skill, create one manually by
              pressing ‘Enter’ or ‘Done’. Give 5-star ratings to your top 5
              skills which are highlighted. Drag and drop to move skills to the
              top
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {values.Skills.map((skill) => (
              <div>
                <Grid container style={{ textAlign: 'end' }}>
                  <Grid item xs={6} className={classes.gridchip}>
                    <Chip label={skill.value} className={classes.chip} />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Rating />
                  </Grid> */}
                </Grid>
              </div>
            ))}
          </Grid>
        </Grid>
        {/* </Grid> */}
        {/* <Typography varient="h4" color="primary"></Typography> */}
      </Form>
    </FormikProvider>
    // </Paper>
  );
}
