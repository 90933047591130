// ----------------------------------------------------------------------

export default function Input({ theme }) {
  return {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56]
          }
        }
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: theme.palette.grey[200],
        color: '#000',
        '&:hover': {
          backgroundColor: theme.palette.grey[200]
        },
        '&.Mui-focused': {
          backgroundColor: theme.palette.grey[200]
        }
        // '&.Mui-disabled': {
        //   backgroundColor: theme.palette.action.disabledBackground
        // }
      },
      underline: {
        '&:before': {
          borderBottomColor: theme.palette.grey[200]
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        color: theme.palette.primary.main,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.grey[500_32]
        },
        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.action.disabledBackground
          }
        }
      }
    }
  };
}
