import React from 'react';
import PropTypes from 'prop-types';
import { Redirect , useLocation } from 'react-router-dom';
import { PATH_PAGE } from 'src/routes/paths';
import LoadingScreen from 'src/components/LoadingScreen';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

function AuthProtect({ children  }) {
  const { isLoading, isAuthenticated } = useAuth();
 

  if (isLoading) {
    return <LoadingScreen />;
  }

 
  if (!isAuthenticated ) {
    return <Redirect to={PATH_PAGE.auth.login} />;
  }

  return <>{children}</>;
}
export default AuthProtect;

function useAuth() {
  const { user, isLoading, isAuthenticated } = useSelector(
    (state) => state.authJwt
  );
  return {
    user: user,
    isLoading: isLoading,
    isAuthenticated: isAuthenticated
  };
}
