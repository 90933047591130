import React from 'react';
import { FormikProvider, useFormik, Form } from 'formik';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControl,
  Grid,
  Select,
  FormLabel,
  TextField,
  CircularProgress,
  Fade,
  Typography,
  MenuItem,
  Dialog,
  DialogContent,
  InputAdornment,
  IconButton,
  FilledInput
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {
  createEducation,
  deleteEducation,
  updateEducation
} from 'src/redux/slices/user';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20'
  },
  paper: {
    // backgroundColor: theme.palette.background.paper,
    // border: '0px solid #000',
    // padding: theme.spacing(2, 4, 3),
    // width: '50%',
    borderRadius: '20'
  },
  cardHeader: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: 'capitalize'
    // justifyContent: 'space-between',
    // display: 'flex'
  },
  input: {
    width: '-webkit-fill-available'
  },
  formControl: {
    width: '100%'
  },
  button: {
    background: '#36B09A',
    width: '140px'
  },
  u: {
    color: '#1786D0',
    fontSize: '14px',
    cursor: 'pointer'
  },
  clos: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  AddEdu: {
    border: '2px dashed #1786D0',
    color: '#1786D0',
    textAlign: 'center',
    padding: '25px',
    borderRadius: '3px'
  }
}));
const materialTheme = createMuiTheme({
  overrides: {
    palette: {
      primary: '#003458'
    },
    MuiPickersToolbar: {
      root: {
        background: 'red !important'
      }
    },
    MuiPickersCalendarHeader: {
      root: {
        background: '#003458 !important',
        color: 'white'
      }
    },
    MuiPickersMonth: {
      root: {
        color: '#003458 !important',
        '&$disabled': {
          color: '#003458'
        }
      }
    },
    MuiPickersYear: {
      root: {
        color: '#003458',
        '&$disabled': {
          color: '#003458'
        },
        '&$selected': {
          backgroundColor: '#003458'
        }
      },
      today: {
        color: '#003458'
      }
    },
    MuiPickersModalDialog: {
      dialogAction: {
        color: '#003458'
      }
    }
  }
});

export default function EducationeModal({
  handleOpen,
  handleClose,
  open,
  data,
  isEdit
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.isLoading);
  const talentId = useSelector((state) => state.authJwt.user._id);

  console.log('data in form', data);
  const formik = useFormik({
    initialValues: data,
    // validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        console.log('editable', isEdit);
        if (isEdit) {
          await dispatch(updateEducation(talentId, values));
        } else {
          await dispatch(createEducation(talentId, values));
        }
      } catch {}
      handleClose();
      resetForm();
    },
    enableReinitialize: true
  });
  const {
    errors,
    touched,
    values,
    getFieldProps,
    handleSubmit,
    setFieldValue
  } = formik;
  const handleDelete = async () => {
    await dispatch(deleteEducation(talentId, data));
    handleClose();
    formik.resetForm();
  };
  console.log('values of formik', values);

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Typography color="primary" type="link" onClick={handleOpen}>
          <Box width="100%">
            <Typography color="primary" className={classes.AddEdu}>
              <u>+ Add New Education</u>
            </Typography>
          </Box>
        </Typography>
        <Dialog
          open={open}
          onClose={handleClose}
          className={classes.paper}
          fullWidth
          maxWidth="md"
        >
          <MuiDialogTitle className={classes.cardHeader}>
            <div className={classes.clos}>
              <Typography variant="h6">
                {' '}
                {isEdit ? 'Edit Education' : 'Add Education'}{' '}
              </Typography>
              <IconButton onClick={handleClose} style={{ padding: '0px' }}>
                <HighlightOffIcon
                  style={{ color: 'white', fontSize: '30px' }}
                />
              </IconButton>
            </div>
          </MuiDialogTitle>
          <DialogContent>
            <FormikProvider value={formik}>
              <Form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }} />
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormLabel color="secondary">
                      <i>Institution</i>
                    </FormLabel>
                    <FilledInput
                      required
                      fullWidth
                      name="company"
                      variant="outlined"
                      color="primary"
                      {...getFieldProps('universityName')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel color="secondary">
                      <i>Degree</i>
                    </FormLabel>
                    <FilledInput
                      required
                      fullWidth
                      name="company"
                      variant="outlined"
                      color="primary"
                      {...getFieldProps('degree')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormLabel color="secondary">
                      <i>Major</i>
                    </FormLabel>
                    <FilledInput
                      required
                      fullWidth
                      name="currentLocation"
                      variant="outlined"
                      color="primary"
                      {...getFieldProps('major')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormLabel>
                      <i>GPA (Optional)</i>
                    </FormLabel>
                    <FilledInput
                      required
                      fullWidth
                      type="number"
                      name="company"
                      variant="outlined"
                      color="primary"
                      {...getFieldProps('gpa')}
                    />
                  </Grid>

                  <Grid container item xs={6} spacing={1}>
                    <Grid item xs={12}>
                      <FormLabel>
                        <i>Start Date</i>
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          views={['month', 'year']}
                          value={moment(values.startDate)._d}
                          inputVariant="filled"
                          onChange={(e) =>
                            setFieldValue(
                              'startDate',
                              moment(e._d).format('MMMM YYYY')
                            )
                          }
                        />
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} spacing={1}>
                    <Grid item xs={12}>
                      <FormLabel>
                        <i>End Date</i>
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          views={['month', 'year']}
                          value={moment(values.endDate)._d}
                          inputVariant="filled"
                          onChange={(e) =>
                            setFieldValue(
                              'endDate',
                              moment(e._d).format('MMMM YYYY')
                            )
                          }
                        />
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel>
                      <i>Description</i>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      multiline
                      variant="filled"
                      minRows={5}
                      {...getFieldProps('description')}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ mb: 3 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    disabled={values._id ? false : true}
                    onClick={handleDelete}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="primary" />
                    ) : (
                      'Delete Education'
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<SaveAltIcon />}
                    onClick={formik.handleSubmit}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="primary" />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Box>
              </Form>
              <br />
            </FormikProvider>
          </DialogContent>
          {/* </Fade> */}
        </Dialog>
      </MuiPickersUtilsProvider>
    </div>
  );
}
