import React from 'react';
import PropTypes from 'prop-types';
import { PATH_APP } from 'src/routes/paths';
import { Redirect } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node
};

function GuestProtect({ children }) {
  const { isLoading, isAuthenticated, hasResume } = useAuth();
  const isMobile = useMediaQuery('(max-width:600px)');
  //console.log('is movile in geustjljlajfadsfjasklfjalfjalfjs', isMobile);
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    //console.log('hasREsume', hasResume);
    if (hasResume && isMobile) {
      return <Redirect to={PATH_APP.general.talentCard} />;
    } else if (hasResume && !isMobile) {
      return <Redirect to={PATH_APP.general.talentCardBackground} />;
    } else {
      return <Redirect to="/app/welcome" />;
    }
  }

  return <>{children}</>;
}

export default GuestProtect;

function useAuth() {
  const { user, isLoading, isAuthenticated, hasResume } = useSelector(
    (state) => state.authJwt
  );
  return {
    user: user,
    isLoading: isLoading,
    isAuthenticated: isAuthenticated,
    hasResume: hasResume
  };
}
