import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Page from 'src/components/Page';
import Container from '@material-ui/core/Container';
import GetAppIcon from '@material-ui/icons/GetApp';
import Link from '@material-ui/core/Link';
// import './Overview.module.css';

// AK
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Cropper from 'react-easy-crop';
import { useCallback } from 'react';

// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import ResumePopup from './ResumePopup';
import { getFormControlLabelUtilityClasses } from '@mui/material';
// import { Modal } from '@material-ui/core';
import Modal from './PhotoModal/Modal';
import Resizer from 'react-image-file-resizer';
import { getTalentCard } from 'src/redux/slices/user';
import EditIcon from '@material-ui/icons/Edit';
import Image from 'material-ui-image';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white'
    // border: '2px solid black'
  },
  tab: {
    margin: 'auto',
    background: 'white'
  },
  tabs: {
    minWidth: '130px'
  },
  box: {
    borderRadius: '15px',
    padding: '25px 40px 0px 40px',
    boxShadow: '0px 0px 11px rgba(0, 0, 0, 0.25)'
  },
  boxs: {
    boxShadow: '0px 0px 11px rgba(0, 0, 0, 0.25)'
  }
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

export default function TalentCardView() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const loading = useSelector((state) => state.user.isLoading);
  const talentId = useSelector((state) => state.authJwt.user._id);
  const talentCard = useSelector((state) => state.user.talentCard);

  //AK
  const selectedImage = talentCard.profilePictureURL ? talentCard.profilePictureURL : "";

  const image = useSelector((state) => state.user.imgData);
  const [imgData, setImgData] = useState(null);
  // const [crop, setCrop] = useState({ x: 0, y: 0 });
  // const [zoom, setZoom] = useState(1);
  const [showModal, setShowModal] = useState(false);

  // let imageData = null;
  // imageData = resizeFile(selectedImage);

  useEffect(() => {
    dispatch(getTalentCard(talentId));
  }, [image]);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
  }, []);

  let firstName, lastName, resumeOrignalName, currentDesignation;
  if (talentCard) {
    firstName = talentCard.firstName;
    lastName = talentCard.lastName;
    resumeOrignalName = talentCard.resumeOrignalName;
    currentDesignation = talentCard.currentDesignation;
  }
  if (loading) {
    return <div>loading...</div>;
  }

  // AK
  const imgHandler = (event) => {
    console.log(event.target.files[0]);
    // setSelectedImage(event.target.files[0]);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <div style={{ paddingTop: '100px' }}>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Item
                style={{
                  background: 'white',
                  padding: '0px 0px',
                  height: '149px'
                }}
                className={classes.boxs}
              >
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    style={{
                      background: '#61BDFB',
                      padding: '0px',
                      height: '150px',
                      width: '150px'
                    }}
                  >
                    {/* AK */}

                    {/* <IconButton
                      color="secondary"
                      aria-label="upload picture"
                      component="span"
                      onClick={modalHandler}
                    >
                      <EditIcon />
                    </IconButton> */}
                    {showModal && <Modal modalHandler={modalHandler} />}

                    {/* AK */}
                    {selectedImage == null && (
                      <Image
                        src="/static/images/svg/user.svg"
                        // onError={({ currentTarget }) => {
                        //   currentTarget.onerror = null; // prevents looping
                        //   currentTarget.src = UserImg;
                        // }}
                        size="small"
                        style={{
                          background: 'rgb(97, 189, 251)',
                          borderRadius: '6px'
                          // height: imgSize,
                        }}
                      ></Image>
                      // <Box
                      //   component="img"
                      //   sx={{
                      //     height: '100%',
                      //     width: '100%',
                      //     maxHeight: { xs: 233, md: 167 },
                      //     maxWidth: { xs: 350, md: 250 }
                      //   }}
                      //   alt="The house from the offer."
                      //   src="/static/images/svg/user.svg"
                      // />
                      // <img src="/static/images/svg/user.svg" height="78%" />
                    )}

                    {selectedImage != null && (
                      <Image
                        src={selectedImage}
                        // onError={({ currentTarget }) => {
                        //   currentTarget.onerror = null; // prevents looping
                        //   currentTarget.src = UserImg;
                        // }}
                        size="small"
                        style={{
                          background: 'rgb(97, 189, 251)',
                          borderRadius: '6px',
                          height: '100%',
                          width: '135px'
                          // objectFit: 'cover'
                          // height: imgSize,
                        }}
                      />
                      // <Box
                      //   component="img"
                      //   sx={{
                      //     height: 200,
                      //     width: 150
                      //   }}
                      //   alt="The house from the offer."
                      //   src={selectedImage}
                      // />
                      // <img src={selectedImage} height="78%" />
                      // <Cropper
                      //   image={imgData}
                      //   crop={crop}
                      //   zoom={zoom}
                      //   aspect={4 / 3}
                      //   onCropChange={setCrop}
                      //   onCropComplete={onCropComplete}
                      //   onZoomChange={setZoom}
                      // />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{ background: 'white', padding: '30px 0px' }}
                  >
                    <Typography variant="h6" color="primary">
                      {firstName + ' ' + lastName}
                    </Typography>
                    <br />
                    <Typography color="primary" style={{ marginLeft: '10%' }}>
                      {currentDesignation}
                    </Typography>
                    <IconButton
                      color="secondary"
                      aria-label="upload picture"
                      component="span"
                      onClick={modalHandler}
                      // style={{ marginBottom: '100px' }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* <Item
                style={{ background: 'black', height: '100%' }}
                className={classes.boxs}
              >
                <Typography style={{ color: 'white' }}>Coming Soon</Typography>
                <br />
                <svg
                  width="62"
                  height="62"
                  viewBox="0 0 62 62"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.2502 18.0833C23.2502 13.8031 26.7199 10.3333 31.0002 10.3333C35.2804 10.3333 38.7502 13.8031 38.7502 18.0833V23.25H43.9168V18.0833C43.9168 10.9497 38.1338 5.16666 31.0002 5.16666C23.8665 5.16666 18.0835 10.9497 18.0835 18.0833V23.25H23.2502V18.0833ZM31.0002 46.5C29.5738 46.5004 28.4172 45.3444 28.4168 43.918C28.4168 43.9176 28.4168 43.9171 28.4168 43.9167V36.1667C28.4168 34.7399 29.5734 33.5833 31.0002 33.5833C32.4269 33.5833 33.5835 34.7399 33.5835 36.1667V43.9167C33.5839 45.343 32.4279 46.4996 31.0015 46.5C31.0011 46.5 31.0006 46.5 31.0002 46.5Z"
                    fill="white"
                  />
                  <path
                    d="M43.9168 23.25H18.0835C13.8033 23.25 10.3335 26.7198 10.3335 31V49.0833C10.3335 53.3636 13.8033 56.8333 18.0835 56.8333H43.9168C48.197 56.8333 51.6668 53.3636 51.6668 49.0833V31C51.6668 26.7198 48.197 23.25 43.9168 23.25ZM33.5835 43.9167C33.5838 45.343 32.4279 46.4997 31.0016 46.5C31.0011 46.5 31.0006 46.5 31.0002 46.5C29.5739 46.5003 28.4171 45.3444 28.4168 43.9181C28.4168 43.9176 28.4168 43.9171 28.4168 43.9167V36.1667C28.4168 34.7399 29.5734 33.5833 31.0002 33.5833C32.427 33.5833 33.5835 34.7399 33.5835 36.1667V43.9167Z"
                    fill="#21C8AA"
                  />
                </svg>
              </Item> */}
              <img
                src="/static/images/png/speedosm.PNG"
                width="100%"
                height="100%"
                alt="profile"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item
                style={{ background: '#61BDFB', padding: '0px 5px 0px 0px' }}
                className={classes.boxs}
              >
                <Grid container>
                  <Grid item xs={5} style={{ padding: '0px' }}>
                    <img
                      src="/static/images/png/resumexp.PNG"
                      alt="resume"
                      height="100%"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      background: 'white',
                      padding: '30px 0px',
                      height: '150px'
                    }}
                  >
                    <Button
                      style={{ color: '#1786D0' }}
                      onClick={() => setOpen(true)}
                    >
                      <u>Change Resume</u>
                    </Button>
                    <br />
                    <Typography
                      color="primary"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        justifyContent: 'space-evenly'
                      }}
                    >
                      {resumeOrignalName}{' '}
                      <Link
                        href={talentCard.resumeURL}
                        target="_blank"
                        rel="noopener"
                      >
                        <GetAppIcon />
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <ResumePopup
          open={open}
          handleOpen={() => setOpen(true)}
          handleClose={() => setOpen(false)}
        />
      </Container>
      <br />
      <br />
    </div>
  );
}
