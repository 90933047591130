import React from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { updateTalent } from 'src/redux/slices/user';
import BasicInfo from './BasicInfo';
import Summary from './Summary';
import Contact from './Contact';
import Education from './Education';
import Experience from './Experience';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      // margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16)
    }
  }
}));

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  firstName: yup
    .string('Enter your First Name')
    // .email('Enter a valid email')
    .required('First Name is required'),
  lasttName: yup
    .string('Enter your Last Name')
    // .email('Enter a valid email')
    .required('Last Name is required')
});

export default function BackgroundPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    firstName,
    lastName,
    currentDesignation,
    noticePeriod,
    currentSalary,
    contactNumber,
    summary,
    email,
    currentLocation,
    experience
  } = useSelector((state) => state.user.talentCard);
  const talentId = useSelector((state) => state.authJwt.user._id);
  const loading = useSelector((state) => state.user.isLoading);
  console.log('selector', loading);
  const basicInfoData = {
    firstName,
    lastName,
    currentDesignation,
    noticePeriod,
    currentSalary,
    contactNumber,
    summary,
    email,
    currentLocation,
    experience
  };

  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
      currentDesignation,
      noticePeriod,
      currentSalary,
      contactNumber,
      summary,
      email,
      currentLocation,
      experience
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      dispatch(updateTalent(talentId, values));
    }
  });
  return (
    <React.Fragment>
      <Hidden smDown>
        <Grid container style={{ paddingBottom: '20px', paddingTop: '20px' }}>
          <Grid item sm={8}>
            <Typography color="primary" variant="h6">
              Background
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
            spacing={3}
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<SaveAltIcon />}
              onClick={formik.handleSubmit}
              disabled={
                JSON.stringify(basicInfoData) !== JSON.stringify(formik.values)
                  ? false
                  : true
              }
            >
              {loading && <CircularProgress size={24} color="primary" />}
              Save
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <br />
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <BasicInfo formik={formik} />
            <br />
            <Summary formik={formik} />
            <br />
            <Education />
          </Grid>
          <Grid item sm={6}>
            <Contact formik={formik} />
            {/* <br /> */}
            <Experience />
          </Grid>
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid container style={{ paddingBottom: '20px' }}>
          <Grid item xs={2}>
            <Link
              to="/app/talent-card"
              // className={classes.icontext}
              color="primary"
              // style={{ display: 'inline-flex' }}
            >
              <ArrowBackIcon
                style={{ color: '#003458', textDecoration: 'underline' }}
              />
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Typography color="primary" variant="h6">
              Background
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
            spacing={3}
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<SaveAltIcon />}
              onClick={formik.handleSubmit}
              disabled={
                JSON.stringify(basicInfoData) !== JSON.stringify(formik.values)
                  ? false
                  : true
              }
            >
              {loading && <CircularProgress size={24} color="primary" />}
              Save
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <br />
        <Grid container spacing={8}>
          <Grid item sm={6}>
            <BasicInfo formik={formik} />
            <br />
            <Contact formik={formik} />
            <Summary formik={formik} />
            <br />
          </Grid>
          <Grid item sm={6}>
            <Education />
            <br />
            <Experience />
          </Grid>
        </Grid>
      </Hidden>
    </React.Fragment>
  );
}
