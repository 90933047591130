import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import settingsReducer from './slices/settings';
import authJwtReducer from './slices/authJwt';
import opportunitiesReducer from './slices/opportunities';
import userReducer from './slices/user';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings', 'user']
};
const authPersistConfig = {
  key: 'authJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  opportunities: opportunitiesReducer,
  user: persistReducer(rootPersistConfig, userReducer)
});

export { rootPersistConfig, rootReducer };
