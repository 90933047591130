import {
  Typography,
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import LocalMallIcon from '@material-ui/icons/LocalMall';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      //   margin: theme.spacing(1),
      width: '120px',
      height: '120px'
    }
  },
  paper: {
    width: 'auto',
    height: 'auto',
    background: '#F1F4F8',
    marginBottom: '10px'
  },
  align: {
    textAlign: 'end'
  },
  font: {
    fontSize: '18px',
    lineHeight: '21px'
  },
  font2: {
    fontSize: '14px'
  },
  content: {
    padding: '0px 20px 20px 90px'
  },
  mallicon: {
    fontSize: '35px'
  },
  avatar: {
    height: '56px',
    width: '56px',
    borderRadius: '2px',
    background: '#FFA84A'
  },
  CardHeader: {
    padding: '10px 8px  0px 16px'
  }
}));

export default function ExperienceCard({ workExp, onEdit }) {
  const classes = useStyles();
  //console.log('expeirnce', workExp);
  return (
    <Card variant="outlined" className={classes.paper}>
      <CardHeader
        className={classes.CardHeader}
        title={workExp.company}
        titleTypographyProps={{ variant: 'subtitle1' }}
        style={{ color: '#003458' }}
        subheader={
          <ul
            style={{
              display: 'flex',
              // justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginLeft: '-40px',
              marginTop: '0px'
            }}
          >
            <li style={{ marginRight: '30px', listStyle: 'none' }}>
              <Typography color="primary" variant="p">
                {workExp.designation}
                {/* {' '}
                jhgjhd{' '} */}
              </Typography>
            </li>
            {/* <li style={{ marginRight: '10px' }}>{workExp.location}</li> */}
            {/* <li style={{ marginRight: '10px' }}>
              <Typography color="primary" variant="p">
                {workExp.}
              </Typography>
            </li> */}
            <li style={{ marginRight: '10px' }}>
              <Typography color="primary" variant="p">
                {workExp.location}
              </Typography>
            </li>
          </ul>
        }
        avatar={
          <Avatar variant="square" className={classes.avatar}>
            <LocalMallIcon className={classes.mallicon} />
          </Avatar>
        }
        action={
          <IconButton aria-label="edit" onClick={() => onEdit(workExp)}>
            <CreateIcon />
          </IconButton>
        }
      />
      {/* <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={11}> */}
      <CardContent className={classes.content}>
        <Typography color="primary" variant="body">
          {workExp.description}
        </Typography>
      </CardContent>
      {/* </Grid>
      </Grid> */}
    </Card>
  );
}
