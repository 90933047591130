import SvgIcon from '@material-ui/core/SvgIcon';
export function HardSkillIcon(props) {
  return (
    <SvgIcon {...props} width="26" height="26" viewBox="0 0 26 26" fill="none">
      <g clip-path="url(#clip0_1874_9900)">
        <path
          d="M0.681134 21.8439C0.343204 22.1843 0.112992 22.6182 0.019666 23.0907C-0.0736602 23.5632 -0.0258998 24.0531 0.156896 24.4982C0.339691 24.9433 0.649295 25.3236 1.04648 25.591C1.44366 25.8583 1.91055 26.0007 2.38798 26C2.70498 26.0006 3.01898 25.9379 3.3119 25.8156C3.60483 25.6933 3.8709 25.5138 4.09482 25.2873L12.0177 17.2917L8.60404 13.8483L0.681134 21.8439ZM20.4828 7.02479L23.9302 5.22435L25.7384 1.69978L24.0541 0L20.5616 1.82479L18.7775 5.3039L14.5756 9.54443L16.2808 11.2653L20.4828 7.02479ZM21.2373 16.2673C21.1279 16.2608 21.0233 16.2348 20.9123 16.2348C20.3235 16.2348 19.7653 16.3565 19.2441 16.5514L9.33761 6.55398C9.53066 6.02797 9.65131 5.46463 9.65131 4.87043C9.65131 4.75841 9.62557 4.65289 9.61914 4.54249C9.44861 2.00824 7.37981 0 4.82518 0C4.08195 0 3.38538 0.183453 2.75637 0.487043L5.96253 3.72264C6.13145 3.8931 6.24728 4.09441 6.32449 4.30709C6.53524 4.88342 6.42102 5.55392 5.96253 6.01823C5.64884 6.33481 5.23701 6.49391 4.82518 6.49391C4.63535 6.49391 4.44713 6.4517 4.26695 6.38352C4.05005 6.30536 3.85248 6.18074 3.68782 6.01823L0.481654 2.78264C0.167494 3.43318 0.00254174 4.14676 -0.000959955 4.87043C-0.000959955 7.44852 1.98902 9.53631 4.50022 9.7084C4.60961 9.71489 4.71417 9.74087 4.82518 9.74087C5.41396 9.74087 5.97219 9.61911 6.49341 9.42429L16.3999 19.4217C16.1967 19.9595 16.0905 20.5297 16.0862 21.1052C16.0862 21.2172 16.1119 21.3228 16.1183 21.4332C16.2889 23.9674 18.3577 25.9756 20.9123 25.9756C21.6555 25.9756 22.3521 25.7922 22.9811 25.4886L19.7749 22.253C19.6134 22.0873 19.4898 21.8878 19.413 21.6686C19.3047 21.3783 19.2812 21.0628 19.3454 20.7594C19.4096 20.4561 19.5586 20.1778 19.7749 19.9574C20.0886 19.6408 20.5005 19.4817 20.9123 19.4817C21.1021 19.4817 21.2903 19.5239 21.4705 19.5921C21.6813 19.6717 21.8807 19.787 22.0497 19.9574L25.2558 23.193C25.5566 22.5582 25.7384 21.8553 25.7384 21.1052C25.7384 18.5271 23.7485 16.4393 21.2373 16.2673Z"
          fill="#7F7F7F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_9900">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function DiamondIcon(props) {
  return (
    <SvgIcon {...props} width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path d="M12 22L5.93748 7H0L12 22Z" fill="#21C8AA" />
      <path d="M19 6L14.7267 0H11.2733L7 6H19Z" fill="#21C8AA" />
      <path d="M19 7H7L13 22L19 7Z" fill="#21C8AA" />
      <path d="M20.0625 7L14 22L26 7H20.0625Z" fill="#21C8AA" />
      <path
        d="M5.91573 6L10.1745 0H5.08954C5.01983 0.00015732 4.95108 0.0153034 4.88857 0.0442764C4.82607 0.0732493 4.77146 0.11528 4.72893 0.167143L0 6H5.91573ZM20.066 6H26L21.2528 0.167143C21.2103 0.11528 21.1557 0.0732493 21.0932 0.0442764C21.0307 0.0153034 20.9619 0.00015732 20.8922 0H15.8072L20.066 6Z"
        fill="#21C8AA"
      />
    </SvgIcon>
  );
}

export function BoostersIcon(props) {
  return (
    <SvgIcon {...props} width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path d="M11 22L8 19V14L16 22H11Z" fill="#7F7F7F" />
      <path
        d="M26.9789 3.021C26.9789 3.021 17.9999 3 10.9999 10C8.00992 12.99 7.99992 18 9.99992 20C11.9999 22 17.0099 21.99 19.9999 19C26.9999 12 26.9789 3.021 26.9789 3.021ZM16.9999 11C16.9999 9.895 17.8949 9 18.9999 9C20.1049 9 20.9999 9.895 20.9999 11C20.9999 12.105 20.1049 13 18.9999 13C17.8949 13 16.9999 12.105 16.9999 11Z"
        fill="#7F7F7F"
      />
      <path
        d="M18.811 18.676C18.811 18.676 19.568 22.46 18.054 23.973C15.784 26.243 12 27 12 27L13 20L18.811 18.676Z"
        fill="#7F7F7F"
      />
      <path
        d="M11.324 11.189C11.324 11.189 7.54 10.432 6.027 11.946C3.757 14.216 3 18 3 18L10 17L11.324 11.189Z"
        fill="#7F7F7F"
      />
      <path
        d="M9.5421 22.3401C9.4971 22.4041 9.4701 22.4781 9.4121 22.5351C8.4371 23.5101 6.9741 23.0231 6.9741 23.0231C6.9741 23.0231 6.4861 21.5601 7.4621 20.5851C7.5201 20.5271 7.5931 20.5011 7.6571 20.4551L7.2001 19.9971C6.8201 20.0971 6.4591 20.2871 6.1621 20.5841C4.7961 21.9501 5.0131 24.9841 5.0131 24.9841C5.0131 24.9841 8.0291 25.2191 9.4131 23.8351C9.7101 23.5381 9.9001 23.1771 10.0001 22.7971L9.5421 22.3401Z"
        fill="#7F7F7F"
      />
    </SvgIcon>
  );
}
