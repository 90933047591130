import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Grid,
  Typography,
  Slider,
  FormLabel,
  FormControl,
  TextField,
  Avatar
} from '@material-ui/core';
import { FormikProvider, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import Divider from '@material-ui/core/Divider';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const useStyles = makeStyles((theme) => ({
  mark: {
    color: '#1786D0'
  },
  inputss: {
    border: '1px solid #DFE3E9',
    bosmizing: 'border-box',
    borderRadius: '5px',
    background: ' #F1F4F8'
  },
  txtcolor: {
    opacity: '0.4',
    fontStyle: 'italic'
  }
}));

const CURRENCY_TYPE = [
  { key: 1, value: 'INR', label: 'Indian Rupee(INR)' },
  { key: 1, value: 'INR', label: 'Indian Rupee(INR)' }
];

const marks = [
  {
    value: 0,
    label: '0'
  },
  {
    value: 2,
    label: '2'
  },
  {
    value: 4,
    label: '4'
  },
  {
    value: 6,
    label: '6'
  },
  {
    value: 8,
    label: '8'
  },
  {
    value: 10,
    label: '10'
  },
  {
    value: 12,
    label: '12'
  },
  {
    value: 14,
    label: '14'
  },
  {
    value: 16,
    label: '16'
  },
  {
    value: 18,
    label: '18'
  },
  {
    value: 20,
    label: '20'
  },
  {
    value: 22,
    label: '22'
  },
  {
    value: 24,
    label: '24'
  }
];

const PrettoSlider = withStyles({
  root: {
    color: '#1786D0',
    height: 8
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -4,
    marginLeft: -8,
    '&:focus, &:hover, &$active': {
      bosmhadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 1px)'
  },
  track: {
    height: 8,
    '& .MuiSlider-mark': {
      color: 'red'
    }
    // borderRadius: 4
  },
  rail: {
    height: 6,
    background: '#F4F4F4',
    border: '1px solid #D6D9DD',
    '.MuiSlider-markLabelActive': {
      color: 'red'
    }
    // borderRadius: 4
  },
  '& .MuiSlider-markLabelActive': {
    color: 'red !important'
  },
  mark: {
    color: '#F4F4F4'
  },
  marks: {
    color: '#F4F4F4'
  }
})(Slider);

const BasicInfo = ({ formik }) => {
  const classes = useStyles();
  const { errors, touched, values, getFieldProps, setFieldValue } = formik;

  const [currency, setCurrency] = React.useState('');

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  console.log('after formik selector', values);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormLabel className={classes.txtcolor}>First Name</FormLabel>
            <TextField
              className={classes.inputss}
              required
              size="small"
              fullWidth
              name="firstName"
              variant="outlined"
              id="firstName"
              placeholder="First Name"
              {...getFieldProps('firstName')}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormLabel className={classes.txtcolor}>Middle Name</FormLabel>
            <TextField
              className={classes.inputss}
              size="small"
              fullWidth
              name="middleName"
              variant="outlined"
              id="lasttName"
              placeholder="-"
              disabled
              // onChange={formik.handleChange}
              // error={touched.lastName && Boolean(errors.lastName)}
              // helperText={touched.lastName && errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormLabel className={classes.txtcolor}>Last Name</FormLabel>
            <TextField
              className={classes.inputss}
              required
              size="small"
              fullWidth
              name="lastName"
              variant="outlined"
              id="lasttName"
              placeholder="Last Name"
              {...getFieldProps('lastName')}
              // onChange={formik.handleChange}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <FormLabel className={classes.txtcolor}>Designation</FormLabel>
            <br />
            <TextField
              size="small"
              className={classes.inputss}
              variant="outlined"
              {...getFieldProps('currentDesignation')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/static/images/svg/king.svg" width="18" />
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                  </InputAdornment>
                )
              }}
              error={touched.contactNumber && Boolean(errors.contactNumber)}
              helperText={touched.contactNumber && errors.contactNumber}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormLabel className={classes.txtcolor}>Current Salary</FormLabel>
            <br />
            <TextField
              size="small"
              className={classes.inputss}
              variant="outlined"
              {...getFieldProps('currentsalary')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/static/images/svg/salary.svg" width="18" />
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                  </InputAdornment>
                )
              }}
              error={touched.contactNumber && Boolean(errors.contactNumber)}
              helperText={touched.contactNumber && errors.contactNumber}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormLabel className={classes.txtcolor}>Currency</FormLabel>
            <br />
            <TextField
              size="small"
              className={classes.inputss}
              variant="outlined"
              {...getFieldProps('CURRENCY_TYPE')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/static/images/svg/rupees.svg" width="18" />
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                  </InputAdornment>
                )
              }}
              error={touched.CURRENCY_TYPE && Boolean(errors.CURRENCY_TYPE)}
              helperText={touched.CURRENCY_TYPE && errors.CURRENCY_TYPE}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {/* <Grid item sm={4}>
            <FormLabel className={classes.txtcolor}>
              Experience (In Years)
            </FormLabel>
            <TextField
              className={classes.inputss}
              required
              size="small"
              fullWidth
              name="experience"
              variant="outlined"
              id="experience"
              placeholder="Enter Your Experience"
              {...getFieldProps('experience')}
              error={touched.experience && Boolean(errors.experience)}
              helperText={touched.experience && errors.experience}
            />
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <FormLabel className={classes.txtcolor}>
              Experience (In Years)
            </FormLabel>
            <TextField
              size="small"
              fullWidth
              className={classes.inputss}
              placeholder="Enter Your Experience"
              variant="outlined"
              {...getFieldProps('experience')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccessTimeFilledIcon style={{ fontSize: '18px' }} />
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                  </InputAdornment>
                )
              }}
              error={touched.experience && Boolean(errors.experience)}
              helperText={touched.experience && errors.experience}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormLabel className={classes.txtcolor}>
              Notice Period (In Weeks)
            </FormLabel>
            <PrettoSlider
              classes={{ markLabel: classes.mark }}
              color="primary"
              aria-label="pretto slider"
              value={values.noticePeriod}
              marks={marks}
              min={0}
              max={24}
              onChange={(event, newValue) =>
                setFieldValue('noticePeriod', newValue)
              }
              // valueLabelDisplay="auto"
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};
// }
export default BasicInfo;
