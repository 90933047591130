import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import Divider from '@material-ui/core/Divider';
import { useSelector } from 'react-redux';
import { Form, FormikProvider } from 'formik';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      //   margin: theme.spacing(1),
      width: '120px',
      height: '120px'
    }
  },
  divider: {
    height: 28,
    margin: 4
  },
  inputss: {
    border: '1px solid #DFE3E9',
    bosmizing: 'border-box',
    borderRadius: '5px'
  },
  txtcolor: {
    opacity: '0.4',
    fontStyle: 'italic'
  }
}));

export default function Contact({ formik }) {
  const classes = useStyles();
  const { errors, touched, getFieldProps } = formik;
  const talent = useSelector(state => state.authJwt.user)
  console.log(talent, 'talents details')
  return (
    <FormikProvider value={formik}>
      <Form>
        <Typography color="primary" className={classes.txtcolor}>
          Contact
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              fullWidth
              className={classes.inputss}
              variant="outlined"
              {...getFieldProps('email')}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color="primary" />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4} style={{ display: 'flex' }}>
            <TextField
              size="small"
              className={classes.inputss}
              variant="outlined"
              {...getFieldProps('contactNumber')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon color="primary" />
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                  </InputAdornment>
                )
              }}
              error={touched.contactNumber && Boolean(errors.contactNumber)}
              helperText={touched.contactNumber && errors.contactNumber}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              className={classes.inputss}
              variant="outlined"
              {...getFieldProps('currentLocation')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon color="primary" />
                  </InputAdornment>
                )
              }}
              error={touched.currentLocation && Boolean(errors.currentLocation)}
              helperText={touched.currentLocation && errors.currentLocation}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
