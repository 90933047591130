import React from 'react';
import classes from './PhotoCard.module.css';

const PhotoCard = (props) => {
  return (
    <div className={`${classes.photocard} ${props.className}`}>
      {props.children}
    </div>
  );
};

export default PhotoCard;
