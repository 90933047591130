import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import loader from '../WelcomeView/loader.gif'

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    },
    background: 'white',
    textAlign: 'center',
    padding: '88px'
  },
  text: {
    paddingBottom: '60px'
  }
}));

export default function LoadingScreen() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  let history = useHistory();

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);

  return (
    <>
      {loading === false ? (
        history.push('/app/talentCard')
      ) : (
        // <div className={classes.root}>
        //   <Typography color="primary" className={classes.text}>
        //     Generating your talentcard...
        //   </Typography>
        //   <img alt="loading" src="/static/images/svg/loadingscreen.svg" />
        // </div>
        <Box sx={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem', overflow: 'hidden'}}>
          <Box sx={{maxWidth: '500px', width: '100%', height: 'auto'}}>

              <img src={loader} width='100%'/>
          </Box>
        </Box>
      )}
    </>
  );
}
