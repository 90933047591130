import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Page from 'src/components/Page';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import BuildIcon from '@material-ui/icons/Build';
import { Divider } from '@material-ui/core';
import ReplyIcon from '@material-ui/icons/Reply';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CheckIcon from '@mui/icons-material/Check';
import { projectUpload } from 'src/redux/slices/user';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getTalentCard } from 'src/redux/slices/user';
import Modal from './BoosterModal/Modal';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import NewModal from './BoosterModal/NewModal';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { projectDelete } from 'src/redux/slices/user';
import { imageUpload } from 'src/redux/slices/user';
import CertiModal from './CertiModal/CertiModal';
import { certiDelete } from 'src/redux/slices/user';
import TextField from '@material-ui/core/TextField';
import NewCertiModal from './CertiModal/NewCertiModal';
import DocuModal from './DocuModal/DocuModal';
import { docuDelete } from 'src/redux/slices/user';
import NewDocuModal from './DocuModal/NewDocuModal';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white'
    // border: '2px solid black'
  },
  tab: {
    margin: 'auto',
    background: 'white'
  },
  tabs: {
    minWidth: '130px'
  },
  box: {
    borderRadius: '15px',
    padding: '25px 40px 0px 40px',
    boxShadow: '0px 0px 11px rgba(0, 0, 0, 0.25)'
  },
  plus: {
    // background: '#F1F4F8',
    border: '1px dashed #000000',
    // borderRadius: '24px',
    padding: '15px',
    fontSize: '25px',
    textAlign: 'center',
    color: 'black',
    height: '71px',
    width: '315px',
    boxShadow: 'none',
    backgroundColor: 'white',
    marginLeft: '80px',
    marginTop: '40px'
  },
  box: {
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(2),
    background: '#F1F4F8',
    border: 'none',
    borderRadius: '24px',
    padding: '30px'
  },
  span: {
    fontSize: '16px'
  },
  span1: {
    fontSize: '16px',
    color: '#52B543'
  },
  pa: {
    fontStyle: 'italic',
    color: '#1786D0',
    marginLeft: '20px',
    marginTop: '30px'
  },
  pb: {
    fontStyle: 'italic',
    color: 'darkgrey',
    marginLeft: '20px'
  },
  input: {
    display: 'none'
  },
  afterupload: {
    display: 'inline-flex'
  },
  projSample: {
    backgroundColor: 'white',
    // boxShadow: 1,
    // width: '680px',
    height: '300px',
    borderTop: '2px solid #40E0D0'
  },
  direction: {
    flexDirection: 'row'
  },
  icon: {
    color: '#52B543',
    marginTop: '10px'
  },
  alignLeft: {
    alignItems: 'center',
    color: 'black',
    cursor: 'pointer'
  },
  alignRight: {
    color: 'black',
    alignItems: 'center',
    cursor: 'pointer'
  },
  root2: {
    minWidth: 275,
    backgroundColor: '#e6f5ff',
    marginLeft: '20%',
    marginRight: '10%',
    height: '200px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    // fontSize: 14,
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  pos: {
    marginBottom: 12
  },
  desc: {
    color: 'black',
    // textDecoration: 'underline',
    cursor: 'pointer'
  },
  btn: {
    color: 'black',
    marginLeft: '33%',
    marginTop: '2%',
    textDecoration: 'underline'
  },
  boxStyle: {
    width: '740px',
    height: '320px'
  },
  boxStyle2: {
    marginLeft: '36%',
    marginTop: '2%'
  },
  box3: {
    height: '380px',
    borderRadius: '3%'
  },
  documents: {
    marginLeft: '20%',
    marginRight: '10%',
    height: '1%',
    width: '120%'
  }
  // deleteIcon: {
  //   cursor: 'pointer',
  //   marginLeft: 10%
  // }
}));

export default function TalentCardView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showCertiModal, setShowCertiModal] = useState(false);
  const [showNewCertiModal, setShowNewCertiModal] = useState(false);
  const [showDocuModal, setShowDocuModal] = useState(false);
  const [showNewDocuModal, setShowNewDocuModal] = useState(false);

  // const [isProjectSelected, setIsProjectSelected] = useState(0);
  // const [selectedProjectFile, setSelectedProjectFile] = useState([]);

  // const [isCertiSelected, setIsCertiSelected] = useState(0);
  // const [selectedCertiFile, setSelectedCertiFile] = useState([]);

  // const [isAchieveSelected, setIsAchieveSelected] = useState(0);
  // const [selectedAchieveFile, setSelectedAchieveFile] = useState([]);

  const talentId = useSelector((state) => state.authJwt.user._id);
  let talentCard = useSelector((state) => state.user.talentCard);
  const project = useSelector((state) => state.user.project);
  const certificate = useSelector((state) => state.user.certificate);
  const document = useSelector((state) => state.user.document);

  const lenProj = talentCard.workSampleProject.length;
  const lenCerti = talentCard.certificates.length;
  const lenDocu = talentCard.candidateDocuments.length;
  // const projectFileName1 = talentCard.workSampleProject[len - 1].title;
  // const projectFileName2 = talentCard.workSampleProject[len - 2].title;
  // const projectFileName3 = talentCard.workSampleProject[len - 3].title;
  // const projects = talentCard.workSampleProject;
  let [counter, setCounter] = useState(0);
  let [certiCounter, setCertiCounter] = useState(0);
  let [docuCounter, setDocuCounter] = useState(0);

  // const data = { talent_id: talentId, worksSample_id: projects[counter]._id };
  const [del, setDel] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const newModalHandler = () => {
    setShowNewModal(!showNewModal);
  };
  const certiModalHandler = () => {
    setShowCertiModal(!showCertiModal);
  };
  const certiNewModalHandler = () => {
    setShowNewCertiModal(!showNewCertiModal);
  };
  const docuModalHandler = () => {
    setShowDocuModal(!showDocuModal);
  };
  const newDocuModalHandler = () => {
    setShowNewDocuModal(!showNewDocuModal);
  };

  talentCard = useSelector((state) => state.user.talentCard);
  const projects = talentCard.workSampleProject;
  const certificates = talentCard.certificates;
  const documents = talentCard.candidateDocuments;
  let dataProj = {};
  let dataCerti = {};
  let dataDocu = {};

  // if (lenProj != 0) {
  //   dataProj = { talent_id: talentId, worksSample_id: projects[counter]._id };
  // }
  // if (lenCerti != 0) {
  //   dataCerti = {
  //     talent_id: talentId,
  //     certificate_id: certificates[certiCounter]._id
  //   };
  // }

  const deleteHandler = () => {
    // setDel(!del);
    dataProj = { talent_id: talentId, worksSample_id: projects[counter]._id };
    dispatch(projectDelete(talentId, dataProj));
    if (counter == lenProj - 1) {
      setCounter(--counter);
    }
    // window.location.reload();
  };
  const deleteCertiHandler = () => {
    dataCerti = {
      talent_id: talentId,
      certificate_id: certificates[certiCounter]._id
    };
    dispatch(certiDelete(talentId, dataCerti));
    if (certiCounter == lenCerti - 1) {
      setCertiCounter(--certiCounter);
    }
  };
  const deleteDocuHandler = () => {
    dataDocu = {
      talent_id: talentId,
      document_id: documents[docuCounter]._id
    };
    dispatch(docuDelete(talentId, dataDocu));
    if (docuCounter == lenDocu - 1) {
      setDocuCounter(--docuCounter);
    }
  };
  useEffect(() => {
    dispatch(getTalentCard(talentId));
  }, [project, certificate, document]);

  // const ProjectChangeHandler = (event) => {
  //   setSelectedProjectFile((selectedProjectFile) => [
  //     ...selectedProjectFile,
  //     event.target.files[0]
  //   ]);
  //   setIsProjectSelected(isProjectSelected + 1);
  // };

  // const CertiChangeHandler = (event) => {
  //   setSelectedCertiFile((selectedCertiFile) => [
  //     ...selectedCertiFile,
  //     event.target.files[0]
  //   ]);
  //   setIsCertiSelected(isCertiSelected + 1);
  // };

  // const AchieveChangeHandler = (event) => {
  //   setSelectedAchieveFile((selectedAchieveFile) => [
  //     ...selectedAchieveFile,
  //     event.target.files[0]
  //   ]);
  //   setIsAchieveSelected(isAchieveSelected + 1);
  // };

  // const saveHandler = () => {
  //   // console.log('Click');
  //   dispatch(projectUpload(talentId, selectedProjectFile[0]));
  // };

  const addCounter = () => {
    // if (counter == len - 1) {
    //   setCounter(0);
    // }
    setCounter(++counter);
    // console.log(counter);
  };
  const redCounter = () => {
    setCounter(--counter);
  };

  const addCertiCounter = () => {
    setCertiCounter(++certiCounter);
  };
  const redCertiCounter = () => {
    setCertiCounter(--certiCounter);
  };
  const addDocuCounter = () => {
    setDocuCounter(++docuCounter);
  };
  const redDocuCounter = () => {
    setDocuCounter(--docuCounter);
  };

  return (
    <React.Fragment>
      <div>
        <Link
          to="/app/talent-card"
          // className={classes.icontext}
          color="primary"
          // style={{ display: 'inline-flex' }}
        >
          <ArrowBackIcon
            style={{ color: '#003458', textDecoration: 'underline' }}
          />
        </Link>
        {/* <img src="/static/images/png/boostercoming.PNG" width="100%" /> */}
      </div>

      {/* Added Booster AK */}

      <Page title="Qrata Talent" className={classes.root}>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography color="primary" variant="h6">
                Booster
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            ></Grid>
          </Grid>
        </div>
        <br />
        <Divider />
        <br />

        {showModal && <Modal modalChange={modalHandler} />}
        {showNewModal && (
          <NewModal modalChange={newModalHandler} counter={counter} />
        )}
        {/* /////////////////////////////////////////////// */}

        {/* Projects */}
        <Grid container spacing={16} justify="flex-start">
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={6}
            className={classes.projSample}
          >
            <Box boxShadow={1} className={classes.box3}>
              <Box sx={{ flexGrow: 1 }} className={classes.boxStyle}>
                <Typography className={classes.pa}>
                  Projects (.pdf, .jpg)
                </Typography>
                <br />
                <Typography className={classes.pb}>
                  Add your work / non-work projects
                </Typography>
                <br />
                <Grid
                  container
                  // xs={4}
                  // sm={4}
                  // md={12}

                  // className={classes.direction}
                >
                  {lenProj != 0 ? (
                    <Card className={classes.root2}>
                      <CardContent>
                        <img src="https://dev-admin.highpo.co/static/media/workSample.bb2e9b61.svg" />
                        <DeleteOutlineIcon
                          color="secondary"
                          fontSize="medium"
                          classname={classes.deleteIcon}
                          onClick={deleteHandler}
                          style={{
                            // color: 'red',
                            marginLeft: '62%',
                            marginBottom: '20%',
                            cursor: 'pointer'
                          }}
                        ></DeleteOutlineIcon>
                        <Typography
                          variant="h5"
                          className={classes.title}
                          color="textSecondary"
                          onClick={newModalHandler}
                          gutterBottom
                        >
                          {projects[counter].title}
                        </Typography>

                        <Typography
                          variant="body1"
                          component="p"
                          className={classes.desc}
                        >
                          {projects[counter].description.substring(0, 35)} .....
                        </Typography>
                      </CardContent>
                      <CardActions></CardActions>
                    </Card>
                  ) : (
                    ''
                  )}
                  <br />

                  <br />
                </Grid>
                <Box className={classes.boxStyle2}>
                  {counter > 0 ? (
                    <KeyboardArrowLeftIcon
                      className={classes.alignLeft}
                      onClick={redCounter}
                    ></KeyboardArrowLeftIcon>
                  ) : (
                    ''
                  )}
                  {counter != lenProj - 1 && lenProj != 0 ? (
                    <KeyboardArrowRightIcon
                      className={classes.alignRight}
                      onClick={addCounter}
                    ></KeyboardArrowRightIcon>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
              {lenProj != 0 && (
                <Button
                  size="small"
                  className={classes.btn}
                  onClick={modalHandler}
                >
                  +Add New Project
                </Button>
              )}
              {lenProj == 0 && (
                <Button
                  size="small"
                  className={classes.btn}
                  onClick={modalHandler}
                  style={{ marginBottom: '100%' }}
                >
                  +Add New Project
                </Button>
              )}
            </Box>
          </Grid>

          {/* Certifications //////////////// */}

          {showCertiModal && <CertiModal modalHandler={certiModalHandler} />}
          {showNewCertiModal && (
            <NewCertiModal
              modalChange={certiNewModalHandler}
              counter={certiCounter}
            />
          )}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={5}
            style={{ marginLeft: '5%' }}
            className={classes.projSample}
          >
            <Box boxShadow={1} className={classes.box3}>
              <Box sx={{ flexGrow: 1 }} className={classes.boxStyle}>
                <Typography className={classes.pa}>
                  Certifications (.pdf, .jpg)
                </Typography>
                <br />
                <Typography className={classes.pb}>
                  Add your certifications
                </Typography>
                <br />
                <Grid container>
                  {lenCerti != 0 ? (
                    <Card className={classes.root2}>
                      <CardContent>
                        <img src="https://dev-admin.highpo.co/static/media/certificates.4c53e9c7.svg" />
                        <DeleteOutlineIcon
                          color="secondary"
                          fontSize="medium"
                          classname={classes.deleteIcon}
                          onClick={deleteCertiHandler}
                          style={{
                            // color: 'red',
                            marginLeft: '62%',
                            marginBottom: '20%',
                            cursor: 'pointer'
                          }}
                        ></DeleteOutlineIcon>
                        <Typography
                          variant="h5"
                          className={classes.title}
                          color="textSecondary"
                          onClick={certiNewModalHandler}
                          gutterBottom
                        >
                          {certificates[certiCounter].title}
                        </Typography>
                      </CardContent>
                      <CardActions></CardActions>
                    </Card>
                  ) : (
                    ''
                  )}
                </Grid>
                <Box className={classes.boxStyle2}>
                  {certiCounter > 0 ? (
                    <KeyboardArrowLeftIcon
                      className={classes.alignLeft}
                      onClick={redCertiCounter}
                    ></KeyboardArrowLeftIcon>
                  ) : (
                    ''
                  )}
                  {certiCounter != lenCerti - 1 && lenCerti != 0 ? (
                    <KeyboardArrowRightIcon
                      className={classes.alignRight}
                      onClick={addCertiCounter}
                    ></KeyboardArrowRightIcon>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
              <Button
                size="small"
                className={classes.btn}
                onClick={certiModalHandler}
              >
                +Add New Certificate
              </Button>
            </Box>
          </Grid>

          {/* Documents */}
          {showDocuModal && <DocuModal modalHandler={docuModalHandler} />}
          {showNewDocuModal && (
            <NewDocuModal
              modalHandler={newDocuModalHandler}
              counter={docuCounter}
            />
          )}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={6}
            style={{ marginTop: '9%' }}
            className={classes.projSample}
          >
            <Box boxShadow={1} className={classes.box3}>
              <Box sx={{ flexGrow: 1 }} className={classes.boxStyle}>
                <Typography className={classes.pa}>
                  Documents (.pdf, .jpg)
                </Typography>
                <br />
                <Typography className={classes.pb}>
                  Add your documents
                </Typography>
                <br />
                <Grid container>
                  {lenCerti != 0 ? (
                    <Card className={classes.root2}>
                      <CardContent>
                        <img
                          src="https://icon-library.com/images/page-icon/page-icon-5.jpg"
                          style={{
                            height: '50px',
                            width: '50px'
                          }}
                        />
                        <DeleteOutlineIcon
                          color="secondary"
                          fontSize="medium"
                          classname={classes.deleteIcon}
                          onClick={deleteDocuHandler}
                          style={{
                            // color: 'red',
                            marginLeft: '62%',
                            marginBottom: '20%',
                            cursor: 'pointer'
                          }}
                        ></DeleteOutlineIcon>
                        <Typography
                          variant="h5"
                          className={classes.title}
                          color="textSecondary"
                          onClick={newDocuModalHandler}
                          gutterBottom
                        >
                          {/* {documents[docuCounter].title} */}
                        </Typography>
                      </CardContent>
                      <CardActions></CardActions>
                    </Card>
                  ) : (
                    ''
                  )}
                </Grid>
                <Box className={classes.boxStyle2}>
                  {docuCounter > 0 ? (
                    <KeyboardArrowLeftIcon
                      className={classes.alignLeft}
                      onClick={redDocuCounter}
                    ></KeyboardArrowLeftIcon>
                  ) : (
                    ''
                  )}
                  {docuCounter != lenDocu - 1 && lenDocu != 0 ? (
                    <KeyboardArrowRightIcon
                      className={classes.alignRight}
                      onClick={addDocuCounter}
                    ></KeyboardArrowRightIcon>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
              <Button
                size="small"
                className={classes.btn}
                onClick={docuModalHandler}
              >
                +Add New Document
              </Button>
            </Box>
          </Grid>
        </Grid>

        <br />
      </Page>
    </React.Fragment>
  );
}
