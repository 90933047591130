import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Divider,
  Tabs,
  Tab,
  Grid,
  Typography
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { HardSkillIcon, DiamondIcon, BoostersIcon } from './CustomIcons';
// import DiamondIcon from './diamond.svg';
// import BoostersIcon from './boosters.svg';

const talentCardDrawer = [
  { text: 'Background', icon: <PersonIcon />, link: 'background' },
  { text: 'Hard Skills', icon: <HardSkillIcon />, link: 'hard-skills' },
  { text: 'Soft Skills', icon: <DiamondIcon />, link: 'soft-skills' },
  // { text: 'Boosters', icon: <BoostersIcon />, link: 'boosters' }
  { text: 'Boosters', icon: <BoostersIcon />, link: 'BoosterNew' }

  // { text: 'Soft Skills', icon: <SvgIcon component={HardSkillIcon} /> },
  // { text: 'Boosters', icon: <SvgIcon component={HardSkillIcon} /> }
];

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.grey[200]
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    // width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  listColor: {
    color: theme.palette.secondary.main
  },
  mobileButton: {
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.19)',
    borderRadius: '2px',
    background: '#fff',
    height: '50px'
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function SideBar({ value, handleChange }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          onMouseEnter={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
          classes={{
            paper: clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
              width: '10px'
            })
          }}
        >
          <div className={classes.toolbar}>
            {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <PersonIcon /> : <FlightTakeoffIcon />}
          </IconButton> */}
          </div>
          <Divider />
          <List>
            {talentCardDrawer.map((text, index) => (
              <ListItem
                component={(props) => (
                  <Link to={`/app/talent-card/${text.link}`} {...props} />
                )}
              >
                <ListItemIcon>{text.icon}</ListItemIcon>
                <ListItemText
                  secondary={
                    <Typography type="body2" color="primary">
                      {text.text}
                    </Typography>
                  }
                  style={{ textTransform: 'capitalize' }}
                />
              </ListItem>
            ))}
          </List>
          {/* <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            orientation="vertical"
            textColor="secondary"
          >
            {talentCardDrawer.map((text, index) => (
              <Tab
                key={text}
                // label={text}
                component={(props) => (
                  <ListItem {...props}>
                    <ListItemIcon>{text.icon}</ListItemIcon>
                    <ListItemText
                      secondary={
                        <Typography type="body2" color="primary">
                          {text.text}
                        </Typography>
                      }
                      style={{ textTransform: 'capitalize' }}
                    />
                  </ListItem>
                )}
                {...a11yProps(3)}
              />
            ))}
          </Tabs> */}
        </Drawer>
      </Hidden>
      <Hidden smUp>
        <Grid container spacing={1}>
          {talentCardDrawer.map((text, index) => (
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                href={`/app/talent-card/${text.link}`}
                startIcon={text.icon}
                className={classes.mobileButton}
              >
                {text.text}
              </Button>
            </Grid>
          ))}
        </Grid>
        <br />
        <br />
      </Hidden>
    </React.Fragment>
  );
}
