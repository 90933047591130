import {
  Typography,
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import SchoolIcon from '@mui/icons-material/School';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      //   margin: theme.spacing(1),
      width: '120px',
      height: '120px'
    }
  },
  paper: {
    width: 'auto',
    // height: '75px',
    background: '#F1F4F8',
    marginBottom: '10px'
  },
  align: {
    textAlign: 'end'
  },
  font: {
    fontSize: '18px',
    lineHeight: '21px'
  },
  font2: {
    fontSize: '14px'
  },
  mallicon: {
    fontSize: '35px'
  },
  avatar: {
    height: '56px',
    width: '56px',
    borderRadius: '2px',
    background: '#FFA84A'
  },
  CardHeader: {
    padding: '10px 8px  8px 16px'
  }
}));

export default function EducatinCard({ education, onEdit }) {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.paper}>
      <CardHeader
        className={classes.CardHeader}
        title={education.universityName}
        titleTypographyProps={{ variant: 'subtitle1' }}
        style={{ color: '#003458' }}
        subheader={
          <ul
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginLeft: '-40px'
            }}
          >
            {/* <li style={{ listStyle: 'none' }}></li> */}
            <li style={{ listStyle: 'none' }}>
              <Typography color="primary" variant="p">
                {education.degree}
              </Typography>
            </li>
            {/* <li></li> */}
            <li>
              <Typography color="primary" variant="p">
                {education.major}
              </Typography>
            </li>
            <li>
              <Typography color="primary" variant="p">
                {' '}
                {education.startDate}-{education.endDate}
              </Typography>
            </li>
          </ul>
        }
        avatar={
          <Avatar variant="square" className={classes.avatar}>
            <SchoolIcon
              className={classes.mallicon}
              style={{ fontSize: '35px' }}
            />
          </Avatar>
        }
        action={
          <IconButton aria-label="edit" onClick={() => onEdit(education)}>
            <CreateIcon />
          </IconButton>
        }
      />
      {/* <CardContent>
        <Typography color="primary" variant="body">
          {education.description}
        </Typography>
      </CardContent> */}
    </Card>
  );
}
