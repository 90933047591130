import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Page from 'src/components/Page';
import Button from '@material-ui/core/Button';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    display: 'flex'
    // border: '2px solid black'
  },
  paper: {
    padding: '20px',
    border: '1px solid #B5B5B5'
  },
  t1: {
    color: '#F1AD2E'
  },
  t2: {
    color: '#C30D51'
  },
  t3: {
    color: '#F66126'
  },
  button1: {
    width: '-webkit-fill-available',
    background: '#F1AD2E'
  },
  button2: {
    width: '-webkit-fill-available',
    background: '#C30D51'
  },
  button3: {
    width: '-webkit-fill-available',
    background: '#F66126'
  },
  eyeof: {
    textAlign: 'right'
  }
}));

export default function TalentCardView() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <div style={{ display: 'flex' }}>
            <Grid item xs={6}>
              <Typography color="primary">Grit Assesment</Typography>
            </Grid>
            <Grid item xs={6} className={classes.eyeof}>
              <VisibilityOffIcon color="primary" />
            </Grid>
          </div>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.t1}>
              What is a Grit Assessment?
            </Typography>
            <Typography color="primary">
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to make a type specimen book. It has survived not only five
              centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged. It was popularised in the 1960s
              with the release of Contrary to popular belief, Lorem Ipsum is not
              simply random text. It has roots in a piece of ...
            </Typography>
            <Button className={classes.button1}>Take Assesment</Button>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <div style={{ display: 'flex' }}>
            <Grid item xs={6}>
              <Typography color="primary">Personality Assesment</Typography>
            </Grid>
            <Grid item xs={6} className={classes.eyeof}>
              <VisibilityOffIcon color="primary" />
            </Grid>
          </div>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.t2}>
              What is a Personality Assessment?
            </Typography>
            <Typography color="primary">
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to make a type specimen book. It has survived not only five
              centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged. It was popularised in the 1960s
              with the release of Contrary to popular belief, Lorem Ipsum is not
              simply random text. It has roots in a piece of ...
            </Typography>
            <Button className={classes.button2}>Take Assesment</Button>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <div style={{ display: 'flex' }}>
            <Grid item xs={10}>
              <Typography color="primary">
                Emotional Intelligence Assesment
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.eyeof}>
              <VisibilityOffIcon color="primary" />
            </Grid>
          </div>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.t3}>
              What is Emotional Intelligence Assessment?
            </Typography>
            <Typography color="primary">
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to make a type specimen book. It has survived not only five
              centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged. It was popularised in the 1960s
              with the release of Contrary to popular belief, Lorem Ipsum is not
              simply random text. It has roots in a piece of ...
            </Typography>
            <Button className={classes.button3}>Take Assesment</Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
