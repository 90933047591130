import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography, Grid, Card, TextField } from '@material-ui/core';
import { FormikProvider } from 'formik';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      //   margin: theme.spacing(1),
      width: '120px',
      height: '120px'
    }
  },
  paper: {
    width: 'auto',
    height: 'auto',
    background: '#F1F4F8',
    padding: '10px',
    border: '1px solid #DFE3E9'
  },
  font: {
    fontSize: '18px',
    lineHeight: '21px'
  },
  input: {
    color: '#003458'
  },
  txtcolor: {
    opacity: '0.4',
    fontStyle: 'italic'
  }
}));

export default function Summary({ formik }) {
  const classes = useStyles();
  // const { getFieldProps } = formik;
  return (
    <div>
      <Typography color="primary" className={classes.txtcolor}>
        <i>Summary</i>
      </Typography>
      <Card className={classes.paper}>
        <FormikProvider value={formik}>
          <TextField
            fullWidth
            multiline
            rows={4}
            InputProps={{ disableUnderline: true, className: classes.input }}
            // {...getFieldProps('summary')}
          />
        </FormikProvider>
      </Card>
    </div>
  );
}
