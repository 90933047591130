import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Typography,
  Hidden,
  Paper,
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Button,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { updateTalent } from 'src/redux/slices/user';
import Software2 from './Software2';
import Skills from './Skills2';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white'
    // border: '2px solid black'
  },
  headerWithBack: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  resume: {
    // position: 'absolute',
    // right: '70px',
    bottom: '0px',
    textAlign: 'end'
  }
}));
export default function HardSkills() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const skills = useSelector((state) => state.user.talentCard.skills);
  const loading = useSelector((state) => state.user.isLoading);
  const talentId = useSelector((state) => state.authJwt.user._id);
  const formik = useFormik({
    initialValues: {
      skills: skills
      // software: []
    },
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      dispatch(updateTalent(talentId, values));
    }
  });

  // const { values, handleSubmit, setFieldValue } = formik;
  // console.log('skillls sssssssss', skills);

  return (
    <FormGroup>
      <div style={{ height: '50px' }}>
        <Grid container>
          <Grid item xs={6}>
            <Hidden smDown>
              <Typography color="primary" variant="h6">
                <i>Hard Skills</i>
              </Typography>
            </Hidden>
            <Hidden smUp>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Link
                  to="/app/talent-card"
                  // className={classes.icontext}
                  color="primary"
                  // style={{ display: 'inline-flex' }}
                >
                  <ArrowBackIcon
                    style={{ color: '#003458', textDecoration: 'underline' }}
                  />
                </Link>
                <Typography
                  color="primary"
                  variant="h6"
                  className={classes.headerWithBack}
                >
                  <i>Hard Skills</i>
                </Typography>
              </div>
            </Hidden>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            spacing={3}
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<SaveAltIcon />}
              onClick={formik.handleSubmit}
              // disabled={
              //   JSON.stringify(skills) !== JSON.stringify(skills)
              //     ? false
              //     : true
              // }
            >
              {loading && <CircularProgress size={24} color="primary" />}
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
      {/* <br /> */}
      <Divider />
      <br />
      <diV>
        <Typography style={{ color: 'grey' }}>
          <i>Add A Hard Skill</i>
        </Typography>
        <Skills formik={formik} />
      </diV>
      {/* <Grid item xs={6}>
          <Typography style={{ color: 'grey' }}>
            <i> Add A Software Skill</i>
          </Typography>
          <Software2 />
          <br />
        </Grid> */}
      {/* // </Grid> */}
      {/* <Typography color="primary" variant="p" className={classes.resume}>
        Scanned from <u style={{ color: '#1786D0' }}>Resume-123.jpg</u>
      </Typography> */}
    </FormGroup>
  );
}
