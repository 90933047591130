import React from 'react';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Downshift from 'downshift';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const styles = (theme) => ({
  chipContainer: {
    backgroundColor: 'transparent',
    display: 'flex',
    marginBottom: 10,
    width: '100%'
  },
  chip: {
    marginTop: 10,
    marginRight: 5
  },
  paper: {
    maxHeight: '150px',
    overflowY: 'auto'
  }
});

const renderInput = (inputProps) => {
  const { InputProps, classes, availableItems } = inputProps;

  const allItemSelected = availableItems.length === 0;

  return (
    <TextField
      fullWidth
      variant="outlined"
      //   label={
      //     allItemSelected ? 'No more character to add' : 'Choose a character'
      //   }
      placeholder="Select A Skills"
      disabled={allItemSelected}
      InputProps={{
        classes: {
          input: classes.input
        },
        ...InputProps
      }}
    />
  );
};

const renderChipList = (inputProps) => {
  const { classes, selectedItem, onRemoveItem } = inputProps;
  return (
    <div>
      <Typography color="primary">
        Note : If you are unable to find a skill, type it and enter to create a
        new skill. Top 5 skills are highlighted
      </Typography>
      <div className={classes.chipContainer}>
        {selectedItem.length > 0 &&
          selectedItem.map((item) => (
            <div>
              <Chip
                key={item}
                className={classes.chip}
                label={item}
                deleteIcon={<CancelIcon />}
                onDelete={() => onRemoveItem(item)}
                onClick={() => onRemoveItem(item)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

const renderSuggestion = (params) => {
  const { item, index, itemProps, highlightedIndex, selectedItem } = params;
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem.indexOf(item.name) > -1;

  return (
    !isSelected && (
      <MenuItem
        {...itemProps}
        key={item.id}
        selected={isHighlighted}
        component="div"
      >
        {item.name}
      </MenuItem>
    )
  );
};

const getSuggestions = (inputValue, itemList) =>
  itemList.filter((item) =>
    item.name.toLowerCase().includes(inputValue.toLowerCase())
  );

function MultiChipSelect(props) {
  const { classes, availableItems, onRemoveItem, ...rest } = props;

  return (
    <Downshift {...rest}>
      {({
        getInputProps,
        getItemProps,
        inputValue,
        selectedItem,
        highlightedIndex,
        toggleMenu,
        isOpen
      }) => (
        <div>
          {renderInput({
            classes,
            selectedItem,
            availableItems,
            InputProps: {
              ...getInputProps({
                onClick: () => toggleMenu()
              })
            }
          })}

          {renderChipList({
            classes,
            onRemoveItem,
            selectedItem
          })}

          {isOpen && (
            <Paper className={classes.paper} square>
              {getSuggestions(inputValue, availableItems).map((item, index) =>
                renderSuggestion({
                  item,
                  index,
                  itemProps: getItemProps({
                    item: item.name
                  }),
                  highlightedIndex,
                  selectedItem
                })
              )}
            </Paper>
          )}
        </div>
      )}
    </Downshift>
  );
}

export default withStyles(styles)(MultiChipSelect);
