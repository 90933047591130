import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useRouteMatch,
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Page from 'src/components/Page';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';
import PersonIcon from '@material-ui/icons/Person';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import Button from '@material-ui/core/Button';
import { GrDiamond } from 'react-icons/gr';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import Background from 'src/views/TalentCardView/BackgroundPanel/Index.js';
import HardSkill from 'src/views/TalentCardView/HardSkillPanel';
import SoftSkills from 'src/views/TalentCardView/SoftSkill';
import Booster from 'src/views/TalentCardView/Booster/Booster';
import SideBar from './SideBar';
import Loading from 'src/views/LoadingScreen/LoadingScreen';
import Speedometer from 'src/layout/Speedometer';
// import Backgroundimg from 'public/static/images/svg/lock.svg';
import Overview from './overview';
import { getTalentCard } from 'src/redux/slices/user';
import { useDispatch, useSelector } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Card className={classes.box}>
            <Typography>{children}</Typography>
          </Card>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  main: {
    // backgroundColor: 'red'
    // overflow: 'hidden'
  },
  tabs: {
    margin: 'auto'
  },
  tab: {
    minWidth: 'auto'
  },
  box: {
    borderRadius: '15px',
    padding: theme.spacing(3),
    // padding: '25px 40px 25px 40px',
    boxShadow: '0px 0px 11px rgba(0, 0, 0, 0.25)'
  },
  resume: {
    position: 'absolute',
    right: '70px',
    bottom: '0px'
  }
}));

export default function TalentCardView({ children }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { path } = useRouteMatch();
  // console.log('parammmmmmmms', params);
  const [value, setValue] = React.useState(0);
  const talentId = useSelector((state) => state.authJwt.user._id);
  const talentCard = useSelector((state) => state.user.talentCard);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (talentId) {
      dispatch(getTalentCard(talentId));
    }
  }, [dispatch, talentId]);

  // const loading = useSelector((state) => state.user.isLoading);
  console.log(
    '4444444444444444444444444444444444444',
    talentId,
    talentCard,
    value
  );

  if (!talentCard) {
    return <div>loading...</div>;
  }
  return (
    <Page title="Qrata Talent">
      {/* <Speedometer /> */}
      <Hidden smDown>
        <Overview />
        <SideBar handleChange={handleChange} value={value} />
      </Hidden>
      <Hidden smUp>
        {path === '/app/talent-card/background' ||
        path === '/app/talent-card/hard-skills' ||
        path === '/app/talent-card/soft-skills' ||
        path === '/app/talent-card/boosters' ? null : (
          <SideBar handleChange={handleChange} value={value} />
        )}
      </Hidden>
      <Box className={classes.main}>{children}</Box>
    </Page>
  );
}
