import React from 'react';
import Card from '../../PhotoModal/Card';
import './BoosterModal.css';
import ClearIcon from '@material-ui/icons/Clear';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector, useDispatch } from 'react-redux';
import { projectUpload } from 'src/redux/slices/user';
import { Button, Container, TextField, Typography } from '@material-ui/core';
import zIndex from '@material-ui/core/styles/zIndex';
import { useEffect } from 'react';
// import LoadingScreen from 'src/views/LoadingScreen/LoadingScreen';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white'
    // border: '2px solid black'
  },
  tab: {
    margin: 'auto',
    background: 'white'
  },
  tabs: {
    minWidth: '130px'
  },
  box: {
    borderRadius: '15px',
    padding: '25px 40px 0px 40px',
    boxShadow: '0px 0px 11px rgba(0, 0, 0, 0.25)'
  },

  box: {
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(2),
    background: '#F1F4F8',
    border: 'none',
    borderRadius: '24px',
    padding: '30px'
  },

  span1: {
    fontSize: '16px',
    color: '#52B543'
  },
  pa: {
    fontStyle: 'italic',
    color: '#1786D0'
  },
  pb: {
    fontStyle: 'italic',
    color: 'lightgrey'
  },
  input: {
    display: 'none'
  },
  afterupload: {
    display: 'inline-flex'
  },
  ipd: {
    marginTop: '15px',
    marginLeft: '480px'
  },
  modal: {
    height: '80%',
    width: '60%',
    position: 'fixed',
    overflow: 'hidden',
    zIndex: '100',
    top: '15vh',
    left: '20%',
    background: 'white',
    overflow: 'scroll'
  },
  header: {
    background: '#21C8AA',
    height: '8%',
    // padding: '1%',
    textAlign: 'center'
  },
  plus: {
    background: 'transparent',
    border: '1px dashed #000000',
    height: '150px',
    width: '100%',
    marginTop: '1%',
    textAlign: 'center',
    padding: '4%',
    color: 'black',
    boxShadow: 'none'
  },
  span: {
    fontSize: '30px',
    color: 'darkgrey'
  },
  ip: {
    marginTop: '1%',
    width: '100%',
    height: '10%',
    borderRadius: '3%'
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'Center',
    position: 'fixed',
    background: '#000000',
    opacity: '0.8',
    zIndex: '1'
  },
  spinner: {
    width: '64px',
    height: '64px',
    border: '8px solid',
    borderColor: '#3d5af1 transparent #3d5af1 transparent',
    borderRadius: '50%',
    Animation: 'spin-anim 1.2s linear infinite'
  }
}));

const Backdrop = (props) => {
  return (
    <React.Fragment>
      <div className="backdrop" onClick={props.modalHandler}></div>
    </React.Fragment>
  );
};

const ModalOverlay = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [projectData, setProjectData] = useState({
    file: null,
    title: null,
    link: null,
    description: null
  });
  // const [load, setLoad] = useState(false);
  // const [save, setSave] = useState(false);
  const talentId = useSelector((state) => state.authJwt.user._id);
  const load = useSelector((state) => state.user.isLoading);

  // useEffect(() => {
  //   setLoad(true);
  //   setTimeout(() => {
  //     setLoad(false);
  //   }, 2000);
  // }, [save]);

  const saveHandler = () => {
    // setSave(true);
    dispatch(projectUpload(talentId, projectData));
    setTimeout(props.modalHandler, 5000);
    // props.modalHandler();
    // window.location.reload();
    // console.log(projectData);
  };

  return (
    <React.Fragment>
      {/* <LoadingScreen /> */}
      <Card className={classes.modal}>
        <header className={classes.header}>
          {/* <button
            style={{
              // position: 'relative',
              background: 'transparent',
              border: 'none',
              marginLeft: '92%'
            }}
            onClick={props.modalHandler}
          >
            <ClearIcon>Close</ClearIcon>
          </button> */}
          <Typography variant="h5">Add Project</Typography>
        </header>
        <Container>
          <Typography
            style={{ color: '#003458', marginTop: '2%', fontWeight: 'bold' }}
          >
            Project
          </Typography>
          <Paper className={classes.plus}>
            <input
              name="file"
              accept="application/pdf"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={(event) => {
                let updatedValue = {};
                updatedValue = { file: event.target.files[0] };
                setProjectData((prevData) => ({
                  ...prevData,
                  ...updatedValue
                }));
              }}
            />
            {projectData.file != null ? (
              <div className={classes.afterupload}>
                <CheckIcon className={classes.span1} />
                <span className={classes.span1}>{projectData.file.name}</span>
              </div>
            ) : (
              <label htmlFor="contained-button-file">
                + <span className={classes.span}>Choose File</span>
              </label>
            )}
          </Paper>
          <Typography
            style={{ color: '#003458', marginTop: '2%', fontWeight: 'bold' }}
          >
            Title
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            name="link"
            id="description"
            type="text"
            placeholder="Add Title"
            className={classes.ip}
            onChange={(event) => {
              let updatedValue = {};
              updatedValue = { title: event.target.value };
              setProjectData((prevData) => ({
                ...prevData,
                ...updatedValue
              }));
            }}
          />
          <Typography
            style={{ color: '#003458', marginTop: '2%', fontWeight: 'bold' }}
          >
            Link
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            name="link"
            id="link"
            type="text"
            placeholder="Add Link"
            className={classes.ip}
            onChange={(event) => {
              let updatedValue = {};
              updatedValue = { link: event.target.value };
              setProjectData((prevData) => ({
                ...prevData,
                ...updatedValue
              }));
            }}
          />
          <Typography
            style={{ color: '#003458', marginTop: '2%', fontWeight: 'bold' }}
          >
            Description
          </Typography>
          <TextField
            multiline
            variant="outlined"
            name="link"
            id="description"
            type="text"
            placeholder="Add Description"
            className={classes.ip}
            onChange={(event) => {
              let updatedValue = {};
              updatedValue = { description: event.target.value };
              setProjectData((prevData) => ({
                ...prevData,
                ...updatedValue
              }));
            }}
            rows={4}
          />
        </Container>
        <footer>
          <Button
            variant="contained"
            style={{
              background: '#21C8AA',
              color: 'white',
              marginTop: '1%',
              marginLeft: '91.2%'
            }}
            onClick={saveHandler}
          >
            {load && <CircularProgress size={24} color="primary" />}
            {!load && 'Save'}
          </Button>
        </footer>
      </Card>
    </React.Fragment>
  );
};

const Modal = (props) => {
  return (
    <React.Fragment>
      <Backdrop modalHandler={props.modalChange} />
      <ModalOverlay modalHandler={props.modalChange} />
    </React.Fragment>
  );
};

export default Modal;
