import React from 'react';
import Card from '../../PhotoModal/Card';
// import './BoosterModal.css';
import ClearIcon from '@material-ui/icons/Clear';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector, useDispatch } from 'react-redux';
import { certiUpload } from 'src/redux/slices/user';
import { Container } from '@material-ui/core';
import { Typography, TextField, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white'
    // border: '2px solid black'
  },
  tab: {
    margin: 'auto',
    background: 'white'
  },
  tabs: {
    minWidth: '130px'
  },
  box: {
    borderRadius: '15px',
    padding: '25px 40px 0px 40px',
    boxShadow: '0px 0px 11px rgba(0, 0, 0, 0.25)'
  },
  box: {
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(2),
    background: '#F1F4F8',
    border: 'none',
    borderRadius: '24px',
    padding: '30px'
  },
  span1: {
    fontSize: '16px',
    color: '#52B543'
  },
  pa: {
    fontStyle: 'italic',
    color: '#1786D0'
  },
  pb: {
    fontStyle: 'italic',
    color: 'lightgrey'
  },
  input: {
    display: 'none'
  },
  afterupload: {
    display: 'inline-flex'
  },
  modal: {
    height: '60%',
    width: '52%',
    position: 'fixed',
    overflow: 'hidden',
    zIndex: '100',
    top: '24vh',
    left: '25%',
    background: 'white',
    overflow: 'scroll'
  },
  header: {
    background: '#21C8AA',
    height: '14%',
    padding: '1%',
    textAlign: 'center'
  },
  plus: {
    background: 'transparent',
    border: '1px dashed #000000',
    height: '150px',
    width: '100%',
    marginTop: '1%',
    textAlign: 'center',
    padding: '4%',
    color: 'black',
    boxShadow: 'none'
  },
  span: {
    fontSize: '30px',
    color: 'darkgrey'
  },
  ip: {
    marginTop: '1%',
    width: '100%',
    height: '10%',
    borderRadius: '3%'
  }
}));

const Backdrop = (props) => {
  return <div className="backdrop" onClick={props.modalHandler}></div>;
};

const ModalOverlay = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [certiData, setCertiData] = useState({
    file: null,
    title: null
  });
  const talentId = useSelector((state) => state.authJwt.user._id);
  const load = useSelector((state) => state.user.isLoading);

  const saveHandler = () => {
    // props.modalHandler();
    dispatch(certiUpload(talentId, certiData));
    setTimeout(props.modalHandler, 5000);
    // window.location.reload();
    // console.log(projectData);
  };

  return (
    <Card className={classes.modal}>
      <header className={classes.header}>
        {/* <h2>Add Certificate</h2> */}
        {/* <button>
          <ClearIcon className="close">Close</ClearIcon>
        </button> */}
        <button
          style={{
            // position: 'relative',
            background: 'transparent',
            border: 'none',
            marginLeft: '80%',
            height: '2px'
          }}
          onClick={props.modalHandler}
        >
          <ClearIcon size="small">Close</ClearIcon>
        </button>
        <Typography variant="h5">Add Certificate</Typography>
      </header>
      <Container>
        <Typography
          style={{ color: '#003458', marginTop: '2%', fontWeight: 'bold' }}
        >
          Certificate
        </Typography>
        <Paper className={classes.plus}>
          <input
            name="file"
            accept="application/pdf"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={(event) => {
              let updatedValue = {};
              updatedValue = { file: event.target.files[0] };
              setCertiData((prevData) => ({
                ...prevData,
                ...updatedValue
              }));
            }}
          />
          {certiData.file != null ? (
            <div className={classes.afterupload}>
              <CheckIcon className={classes.span1} />
              <span className={classes.span1}>{certiData.file.name}</span>
            </div>
          ) : (
            <label htmlFor="contained-button-file">
              + <span className={classes.span}>Upload A File</span>
            </label>
          )}
        </Paper>
        <Typography
          style={{ color: '#003458', marginTop: '2%', fontWeight: 'bold' }}
        >
          Title
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          name="link"
          id="description"
          type="text"
          placeholder="Add Title"
          className={classes.ip}
          onChange={(event) => {
            let updatedValue = {};
            updatedValue = { title: event.target.value };
            setCertiData((prevData) => ({
              ...prevData,
              ...updatedValue
            }));
          }}
        />
        {/* <label htmlFor="description">
          + <span className={classes.span}>Enter Title</span>
        </label> */}
      </Container>
      <footer className="actions">
        {/* <button id="save" onClick={saveHandler}>
          Save
        </button> */}
        <Button
          variant="contained"
          style={{
            background: '#21C8AA',
            color: 'white',
            marginTop: '8%',
            marginLeft: '90%'
          }}
          onClick={saveHandler}
        >
          {load && <CircularProgress size={24} color="primary" />}
          {!load && 'Save'}
        </Button>
        {/* <button onClick={props.modalHandler}>Close</button> */}
      </footer>
    </Card>
  );
};

const CertiModal = (props) => {
  return (
    <React.Fragment>
      <Backdrop modalHandler={props.modalHandler} />
      <ModalOverlay modalHandler={props.modalHandler} />
    </React.Fragment>
  );
};

export default CertiModal;
